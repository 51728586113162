.customerReviewsSection {
  height: 1064px;
  width: 100%;
  display: flex;
  flex-direction: row;

  .clientPart {
    display: flex;
    flex-direction: row;
  }

  .col {
    display: flex;
    flex-direction: column;
    min-width: 350px;

    &.left {
      min-width: 35%;
      align-items: flex-end;

      padding: 0px 0px;
      background-color: var(--general-blue);
    }

    &.right {
      padding-top: 40px;
      padding-left: 50px;

      .productName {
        padding-bottom: 10px;
        font-family: var(--font-family-XB);
        font-size: 22px;
        color: var(--yellow);
      }

      .clientName {
        font-family: var(--font-family-LT);
        font-size: 22px;
        color: var(--general-blue);
        font-weight: var(--font-weight-medium);
        margin: 7px 0px;
        cursor: pointer;

        &:hover {
          opacity: 70%;
        }
      }
    }
  }

  .alignTop {
    margin-top: -200px !important;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.1);
  }

  .content {
    margin-right: 50px;
    justify-content: center;
    width: 360px;

    .title {
      font-family: var(--font-family);
      font-weight: var(--font-weight-bold);
      font-size: 60px;
      font-weight: var(--font-weight-medium);
      color: var(--yellow);
      line-height: 60px;

      text-align: left;
    }

    .text {
      font-family: var(--font-family);

      font-size: 16px;
      font-weight: var(--font-weight-normal);

      color: var(--white);
    }
  }
}

.customerReviewsCard {
  margin-top: -700px;
}

@media only screen and (max-width: 1190px) {
  .customerReviewsSection {
    .col {
      &.right {
        padding-left: 50px;
      }

      &.left {
        padding: 80px 20px;
      }
    }
  }
}

@media only screen and (max-width: 1060px) {
  .customerReviewsSection {
    flex-direction: column;

    .clientPart {
      justify-content: center;
      margin-top: -550px;
    }

    .col {
      &.left {
        min-height: 800px;

        min-width: 100%;
        align-items: center;
      }

      &.right {
        padding-left: 50px;

        .clientName {
          color: var(--white);
        }
      }
    }

    .content {
      width: 100%;
      text-align: center;
      margin-right: 0px;

      .title {
        text-align: center;
      }
    }
  }

  .customerReviewsCard {
    margin-top: -350px;
  }
}

@media only screen and (max-width: 800px) {
  .customerReviewsSection {
    flex-direction: column;

    .clientPart {
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: -450px;
    }

    .col {
      &.left {
        min-height: 800px;

        min-width: 100%;
        align-items: center;
      }

      &.right {
        min-width: auto;
        padding: 40px;

        .clientName {
          color: var(--white);
        }
      }
    }

    .content {
      width: 100%;
      text-align: center;
      margin-right: 0px;

      .title {
        text-align: center;
      }
    }
  }

  .customerReviewsCard {
    margin-top: -320px;
  }
}

@media only screen and (max-width: 600px) {
  .customerReviewsSection {
    flex-direction: column;

    .clientPart {
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: -450px;
    }

    .col {
      &.left {
        min-height: 800px;

        min-width: 100%;
        align-items: center;
      }

      &.right {
        min-width: auto;
        padding: 40px 20px;

        .clientName {
          color: var(--white);
        }
      }
    }

    .content {
      width: 100%;
      text-align: center;
      margin-right: 0px;

      .title {
        text-align: center;
      }
    }
  }

  .customerReviewsCard {
    margin-top: -310px;
  }
}
