.sectionTechnology {
  font-weight: var(--font-weight-normal);
  width: 100%;
  padding-top: 21px;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 821px;
    height: 100%;
    margin-bottom: 62px;

    button {
      margin-top: 34px;
      font-size: var(--line-height-small);
      line-height: var(--line-height-midle);
    }
  }

  .title,
  .description {
    text-align: center;
  }

  .title {
    font-family: var(--font-family);
    font-size: 60px;
    line-height: 70px;
    font-weight: var(--font-weight-bold);
    color: var(--yellow);
    margin-top: 0;
    margin-bottom: 44px;
  }

  .description {
    font-family: var(--font-family);
    font-size: var(--font-size-midle);
    line-height: var(--line-height-xlarge);
    font-weight: var(--font-weight-normal);
    width: 100%;
    min-height: 91px;
  }

  .infoTechnology {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;

    [data-title] {
      line-height: 2;
    }
    [data-text] {
      line-height: 1.5;
    }
  }
}

@media only screen and (max-width: 600px) {
  .sectionTechnology {
    .title {
      font-size: 36px;
    }
    .infoTechnology {
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 370px) {
  .sectionTechnology {
    .infoTechnology {
      justify-content: center;
    }
  }
}
