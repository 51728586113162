.banner {
  position: relative;
  height: 450px;
  width: 100%;
  background-color: var(--general-blue);
  z-index: 1;
  right: 0;
  left: auto;
  padding: 0px;
}

.block {
  position: relative;
  margin-top: -215px;
  min-height: 250px;
  width: 820px;
  padding-left: 40px;
  padding-top: 10px;

  background: #ffffff;
  z-index: 2;
}

.bannerImage {
  position: absolute;
  right: 0;
  left: auto;
  object-fit: cover;
  width: 100%;
}

.title {
  text-transform: uppercase;
  font-family: var(--font-family-LT);
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 10px;
  color: var(--general-blue);
}

.subtitle {
  width: 80%;
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-style: normal;
  font-size: 60px;
  word-break: normal;
  line-height: 65px;

  margin-top: 30px;
  color: #e0bc37;
}

.explanation {
  margin-top: 20px;
  font-size: 20px;
  color: var(--yellow);
}

.descriptionContainer {
  margin-top: 80px;
}

.description {
  font-family: var(--font-family-SB);
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 27px;

  color: var(--general-blue);
}

// @media screen and (max-width: 1180px) {
//   .bannerImage {
//     object-fit: cover;
//   }
// }

@media screen and (max-width: 840px) {
  .block {
    width: 98%;
  }

  .title {
    font-size: 55px;
    line-height: 55px;
  }

  .subtitle {
    width: 90%;
    margin-top: 20px;
    font-size: 45px;
    line-height: 50px;
  }
}

@media screen and (max-width: 481px) {
  .banner {
    height: 240px;
  }

  .block {
    margin-top: -100px;
  }

  .title {
    font-size: 30px;
    line-height: 35px;
  }
  .subtitle {
    margin-top: 20px;
    font-size: 36px;
    line-height: 40px;
  }

  .descriptionContainer {
    margin-top: 40px;
  }

  .description {
    font-size: 18px;
    line-height: 26px;
  }
}
