.container {
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--general-blue);
}

.header {
  display: flex;
  max-width: 1440px;
  width: 100%;
  min-height: 760px;
  position: relative;
}

@media only screen and (max-width: 1350px) {
  .header {
    flex-direction: column;
  }
}
