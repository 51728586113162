.section {
  background-color: var(--yellow);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 58px 0 65px 0;
  }

  .title {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    font-size: 60px;
    line-height: 70px;
    text-align: center;
  }

  .text {
    max-width: 945px;
    padding: 36px 0;
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: var(--white);
  }

  .mediaDataWrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;

    .mediaData,
    .audianceRation {
      text-align: center;
      width: 230px;
      padding-bottom: 40px;
      font-size: 16px;
      line-height: 21px;

      div {
        font-family: var(--font-family);
        font-weight: var(--font-weight-bold);
      }

      div > div:not(:nth-child(1)) {
        color: var(--white);
      }

      img {
        width: 230px;
        height: 230px;
      }
    }
  }

  .schemaContainer {
    max-width: 840px;
    margin: auto;
  }
}

@media only screen and (max-width: 520px) {
  .section {
    .title {
      font-size: 40px;
      line-height: 50px;
    }
  }
}

@media only screen and (max-width: 350px) {
  .section {
    .title {
      font-size: 36px;
      line-height: 46px;
    }
  }
}
