.sectionOurNews {
  width: 100%;
  margin-bottom: 50px;
  display: flex;

  flex-direction: column;

  .blueBlock {
    align-self: flex-end;
    width: 45%;
    background-color: var(--general-blue);
    min-height: 430px;
  }

  .marginNegative {
    margin-top: -430px;
  }

  .header {
    margin-left: 180px;
  }

  .row {
    display: flex;
    flex-direction: row;

    &.spaceBetween {
      justify-content: space-between;
    }
  }

  .col {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .card {
    display: flex;
    flex-direction: row;
    background-color: var(--white);
    padding: 60px 80px;

    flex-wrap: wrap;
    justify-content: space-between;

    width: 100%;
    // height: 390px;

    margin-top: 20px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.1);

    .blockNews {
      width: 50%;

      .accessArrow {
        width: 40px;
        height: auto;
        margin-bottom: 15px;
      }

      .cardTitle {
        font-size: 18px;
        font-weight: var(--font-weight-bold);
        color: var(--general-blue);
        margin-bottom: 10px;
        line-height: 18px;
      }

      .body {
        font-size: 16px;
        font-weight: var(--font-weight-normal);
      }
    }
  }

  .title {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    font-size: 60px;
    line-height: 60px;
    color: var(--yellow);
    margin-top: 0;
    margin-bottom: 0px;
  }

  .seeMoreNewsButton {
    margin: 20px 0 0 auto;
  }
}

@media only screen and (max-width: 1180px) {
  .sectionOurNews {
    .blueBlock {
      width: 90%;
    }
    .title {
      margin-top: 15px;
    }

    .card {
      .blockNews {
        width: 100%;
        margin-bottom: 40px;

        .title {
          margin-bottom: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .sectionOurNews {
    .header {
      margin-left: 100px;
    }
    .title {
      font-size: 36px;
      line-height: 41px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .sectionOurNews {
    .card {
      padding: 20px 30px;
    }

    .seeMoreNewsButton {
      margin-bottom: 50px;
    }

    .blueBlock {
      min-height: 1100px;
    }

    .row {
      &.spaceBetween {
        justify-content: flex-end;
      }
    }

    .marginNegative {
      margin-top: -1080px;
    }
  }
}

@media only screen and (max-width: 380px) {
  .sectionOurNews {
    .blueBlock {
      min-height: 1150px;
    }

    .marginNegative {
      margin-top: -1140px;
    }
  }
}

@media only screen and (max-width: 320px) {
  .sectionOurNews {
    .blueBlock {
      min-height: 1250px;
    }

    .marginNegative {
      margin-top: -1240px;
    }
  }
}
