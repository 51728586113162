@import '../node_modules/normalize.css/normalize.css';
@import url('//hello.myfonts.net/count/3d6984');

@font-face {
  font-family: 'Soleil';
  src: url('./webFonts/Soleil/font.woff2') format('woff2'),
    url('./webFonts/Soleil/font.woff') format('woff');
}

@font-face {
  font-family: 'SoleilXb';
  src: url('./webFonts/SoleilXb/font.woff2') format('woff2'),
    url('./webFonts/SoleilXb/font.woff') format('woff');
}

@font-face {
  font-family: 'SoleilLt';
  src: url('./webFonts/SoleilLt/font.woff2') format('woff2'),
    url('./webFonts/SoleilLt/font.woff') format('woff');
}

@font-face {
  font-family: 'SoleilSb';
  src: url('./webFonts/SoleilSb/font.woff2') format('woff2'),
    url('./webFonts/SoleilSb/font.woff') format('woff');
}

@font-face {
  font-family: 'SoleilSb-Italic';
  src: url('webFonts/SoleilSbItalic/font.woff2') format('woff2'),
    url('webFonts/SoleilSbItalic/font.woff') format('woff');
}

@font-face {
  font-family: 'SoleilBk';
  src: url('webFonts/SoleilBk/font.woff2') format('woff2'),
    url('webFonts/SoleilBk/font.woff') format('woff');
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --font-family: 'Soleil', sans-serif;
  --font-family-XB: 'SoleilXb';
  --font-family-LT: 'SoleilLt';
  --font-family-SB: 'SoleilSb';
  --font-family-SBi: 'SoleilSb-Italic';
  --font-family-BK: 'SoleilBk';

  // font-weight
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-normal: 500;
  --font-weight-medium: 600;
  --font-weight-bold: 700;
  --font-weight-black: 900;

  // font-size
  --font-size-xsmall: 12px;
  --font-size-small: 14px;
  --font-size-midle: 16px;
  --font-size-large: 18px;
  --font-size-xlarge: 21px;

  // line-height
  --line-height-small: 14px;
  --line-height-small-plus: 15px;
  --line-height-midle: 16px;
  --line-height-midle-plus: 17px;
  --line-height-large: 18px;
  --line-height-xlarge: 21px;

  // paddings
  --padding: 1rem;

  // width
  --max-width: 1180px;

  //--path-images: './assets/images/';

  /* colors */
  --yellow: #e0bc37;
  --brightness-yellow: #B3962C;
  --black: #000;
  --white: #fff;
  --general-blue: #201f35;
  --general-blue-light: #2b2947;
  --dark-yellow: #e0bc37;
  --grey: #979797;
  --not-active: #707070;
  --success: #6ed298;
  --danger: #f29588;
  --error: #ff6961;
}

html,
body {
  height: 100%;
  min-width: 320px;
  overflow-x: auto;
}

body {
  color: var(--general-blue);
  margin: 0;
  font-family: var(--font-family);
  font-weight: normal;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

img {
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  justify-content: center;
  max-width: var(--max-width);
  margin: 0 auto;
}

.button {
  min-height: 40px;
  min-width: 180px;
  border-radius: 8px;
  padding: 9px 35px;
  font-size: var(--line-height-small);
  font-weight: var(--font-weight-bold);
  color: var(--white);
  cursor: pointer;

  &.small {
    padding: 9px 25px;
    min-width: 140px;
    max-width: 280px;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    filter: brightness(0.8);
  }

  &:active {
    filter: brightness(0.6);
  }

  &:disabled {
    background-color: var(--not-active);
    &:hover {
      filter: none;
      cursor: initial;
    }
  }
}

.button.button-try-parse,
.button.button-parse {
  svg {
    margin-right: 10px;
  }

  &:hover {
    filter: none;
    background-color: var(--brightness-yellow);
    border-color: var(--brightness-yellow);
  }

  &:disabled {
    background-color: var(--not-active);
  }
}

.button.button-try-parse {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-warn-sm {
  background-color: var(--yellow);
  border: 2px solid var(--yellow);
}

.button-warn-md {
  background-color: var(--yellow);
  border: 2px solid var(--yellow);
}

.button-warn-lg {
  background-color: var(--yellow);
  border: 2px solid var(--yellow);
}

.button-blue-sm {
  background-color: var(--general-blue);
  border: 2px solid var(--general-blue);
}

.button-blue-md {
  background-color: var(--general-blue);
  border: 2px solid var(--general-blue);
}

// -------- customize ---------
.rec.rec-carousel :focus {
  border: none;
  outline: none;
}

// -- home page slider --
.topButtons {
  .rec.rec-slider-container {
    overflow: initial;
  }

  .rec.rec-arrow {
    position: absolute;
    top: -90px;
    width: 37px;
    min-width: 37px;
    height: 28px;
    font-size: 0;
    line-height: inherit;
    z-index: 1;

    &.rec-arrow-left {
      right: 55px;
      filter: invert(1);
      box-shadow: none;
      background: url('assets/icons/arrowRightBlue.svg') center no-repeat;
      transform: rotate(180deg);

      &:hover {
        opacity: 0.5;
      }

      &[disabled] {
        opacity: 0.1;
      }
    }

    &.rec-arrow-right {
      right: 0;
      filter: invert(1);
      box-shadow: none;
      background: url('assets/icons/arrowRightBlue.svg') center no-repeat;

      &:hover {
        opacity: 0.5;
      }

      &[disabled] {
        opacity: 0.1;
      }
    }
  }

  .rec.rec-pagination {
    display: none;
  }
}

.downButtons {
  .rec.rec-slider-container {
    margin: 0;
  }

  .rec.rec-arrow,
  .rec.rec-arrow {
    position: absolute;
    bottom: 25px;
    width: 37px;
    min-width: 37px;
    height: 28px;
    font-size: 0;
    line-height: inherit;
    z-index: 1;

    &.rec-arrow-left {
      left: 30px;
      box-shadow: none;
      background: url('assets/icons/arrowRightBlue.svg') center no-repeat;
      transform: rotate(180deg);

      &:hover {
        opacity: 0.7;
      }

      &[disabled] {
        opacity: 0.4;
      }
    }

    &.rec-arrow-right {
      left: 85px;
      box-shadow: none;
      background: url('assets/icons/arrowRightBlue.svg') center no-repeat;

      &:hover {
        opacity: 0.7;
      }

      &[disabled] {
        opacity: 0.4;
      }
    }
  }

  .rec.rec-pagination {
    display: none;
  }
}

.sideButtons {
  .rec.rec-slider-container {
    margin: 0;
  }

  .rec.rec-arrow,
  .rec.rec-arrow {
    position: absolute;
    bottom: 45px;
    width: 37px;
    min-width: 37px;
    height: 28px;
    font-size: 0;
    line-height: inherit;
    z-index: 1;
    background-color: red;

    &.rec-arrow-left {
      left: -100px;
      box-shadow: none;
      background: url('assets/icons/arrowRightBlue.svg') center no-repeat;
      transform: rotate(180deg);

      &:hover {
        opacity: 0.7;
      }

      &[disabled] {
        opacity: 0.4;
      }
    }

    &.rec-arrow-right {
      right: -100px;
      box-shadow: none;
      background: url('assets/icons/arrowRightBlue.svg') center no-repeat;

      &:hover {
        opacity: 0.7;
      }

      &[disabled] {
        opacity: 0.4;
      }
    }
  }

  .rec.rec-pagination {
    display: none;
  }
}

.howItWorks {
  .rec.rec-slider-container {
    margin: 0;
    padding-bottom: 30px;
  }

  .rec.rec-carousel-wrapper {
    background: var(--white);
    position: relative;
    padding: 30px 30px 60px 30px;
  }

  .rec.rec-arrow,
  .rec.rec-arrow {
    position: absolute;
    bottom: 25px;
    width: 37px;
    min-width: 37px;
    height: 28px;
    font-size: 0;
    line-height: inherit;
    z-index: 1;

    &.rec-arrow-left {
      left: 30px;
      box-shadow: none;
      background: url('assets/icons/arrowRightBlue.svg') center no-repeat;
      transform: rotate(180deg);

      &:hover {
        opacity: 0.7;
      }

      &[disabled] {
        opacity: 0.4;
      }
    }

    &.rec-arrow-right {
      left: 85px;
      box-shadow: none;
      background: url('assets/icons/arrowRightBlue.svg') center no-repeat;

      &:hover {
        opacity: 0.7;
      }

      &[disabled] {
        opacity: 0.4;
      }
    }
  }

  .rec.rec-pagination {
    display: none;
  }
}

.slider1 {
  .rec.rec-slider-container {
    margin: 0;
  }

  .rec.rec-arrow.rec-arrow-left,
  .rec.rec-arrow.rec-arrow-right {
    display: none;
  }

  .rec.rec-dot {
    width: 12px;
    height: 12px;
    border: 1px solid var(--general-blue);
    box-sizing: border-box;
    box-shadow: none;
    background: var(--white);

    &.rec-dot_active {
      background: var(--general-blue);
    }
  }
}

.slider2 {
  .rec.rec-slider-container {
    margin: 0 0 0 120px;
  }

  .rec.rec-arrow.rec-arrow-left,
  .rec.rec-arrow.rec-arrow-right {
    display: none;
  }

  .rec.rec-carousel-wrapper {
    display: flex;
    flex-direction: row-reverse;
    padding-left: 150px;
  }

  .rec.rec-pagination {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 2px;
    height: 460px;
    background: var(--yellow);
    margin-top: 0;
  }

  .rec.rec-dot {
    position: absolute;
    left: -16px;
    width: 24px;
    height: 24px;
    outline: 5px solid var(--white);
    border: 2px solid var(--general-blue);
    box-sizing: border-box;
    box-shadow: none;
    background: var(--white);

    &.rec-dot_active {
      background: var(--general-blue);
      font-family: var(--font-family-XB);

      &:before {
        display: block;
        position: absolute;
        top: -15px;
        left: -140px;
        font-size: 60px;
        line-height: 45px;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 86px;
        height: 2px;
        background: var(--yellow);
        top: 8px;
        left: -93px;
      }
    }

    &:nth-child(1) {
      top: 58px;

      &.rec-dot_active:before {
        content: '1';
      }
    }
    &:nth-child(2) {
      top: 132px;
      &.rec-dot_active:before {
        content: '2';
      }
    }

    &:nth-child(3) {
      top: 206px;
      &.rec-dot_active:before {
        content: '3';
      }
    }
    &:nth-child(4) {
      top: 280px;
      &.rec-dot_active:before {
        content: '4';
      }
    }
  }

  &.x-ranking-slider {
    .text {
      color: var(--yellow);
    }
    .header {
      color: var(--general-blue);
    }
    .rec.rec-dot.rec-dot_active {
      background: var(--yellow);
      &:before {
        color: var(--yellow);
      }
      &:after {
        background: var(--general-blue);
      }
    }
    .rec.rec-dot {
      border-color: var(--yellow);
    }
    .rec-pagination {
      background: var(--general-blue);
    }
  }
}

.downButtonsTechnology {
  .rec.rec-slider-container {
    margin: 0;
  }

  .rec.rec-arrow,
  .rec.rec-arrow {
    position: absolute;
    top: 400px;
    bottom: 45px;
    width: 37px;
    min-width: 37px;
    height: 28px;
    font-size: 0;
    line-height: inherit;
    z-index: 1;

    &.rec-arrow-left {
      left: 40px;
      box-shadow: none;
      background: url('assets/icons/arrowRightBlue.svg') center no-repeat;
      transform: rotate(180deg);

      &:hover {
        opacity: 0.7;
      }

      &[disabled] {
        opacity: 0.4;
      }
    }

    &.rec-arrow-right {
      left: 95px;
      box-shadow: none;
      background: url('assets/icons/arrowRightBlue.svg') center no-repeat;

      &:hover {
        opacity: 0.7;
      }

      &[disabled] {
        opacity: 0.4;
      }
    }
  }

  .rec.rec-pagination {
    display: none;
  }
}

.titleForNotActiveEn {
  //min-height: 114px;
}

@media only screen and (max-width: 1400px) {
  .sideButtons {
    .rec.rec-arrow {
      bottom: 130px;

      &.rec-arrow-left {
        left: 50px;
      }

      &.rec-arrow-right {
        right: 50px;
      }
    }
  }
}

@media only screen and (max-width: 1180px) {
  .container {
    max-width: 100%;
    width: 90%;
  }

  .slider2 {
    .rec.rec-slider-container {
      margin: 0 0 0 80px;
    }

    .rec.rec-arrow.rec-arrow-left,
    .rec.rec-arrow.rec-arrow-right {
      display: none;
    }

    .rec.rec-carousel-wrapper {
      display: flex;
      flex-direction: column-reverse;
      padding-left: 0px;
    }

    .rec.rec-pagination {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 500px;
      height: 2px;
      background: var(--yellow);
      margin-top: 0;
    }

    .rec.rec-dot {
      position: absolute;
      left: -16px;
      width: 24px;
      height: 24px;
      outline: 5px solid var(--white);
      border: 2px solid var(--general-blue);
      box-sizing: border-box;
      box-shadow: none;
      background: var(--white);

      &.rec-dot_active {
        background: var(--general-blue);
        font-family: var(--font-family-XB);

        &:before {
          display: block;
          position: absolute;
          top: 100px;
          left: -5px;
          font-size: 60px;
          line-height: 45px;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 2px;
          height: 56px;
          background: var(--yellow);
          top: 28px;
          left: 10px;
        }
      }

      &:nth-child(1) {
        top: -15px;
        left: 86px;
        &.rec-dot_active:before {
          content: '1';
        }
      }
      &:nth-child(2) {
        top: -15px;
        left: 186px;

        &.rec-dot_active:before {
          content: '2';
        }
      }

      &:nth-child(3) {
        top: -15px;
        left: 286px;

        &.rec-dot_active:before {
          content: '3';
        }
      }
      &:nth-child(4) {
        top: -15px;
        left: 386px;

        &.rec-dot_active:before {
          content: '4';
        }
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .downButtonsTechnology {
    .rec.rec-arrow {
      position: absolute;
      top: auto;
      bottom: 30px;
      width: 37px;
      min-width: 37px;
      height: 28px;
      font-size: 0;
      line-height: inherit;
      z-index: 1;
    }
  }
}

@media only screen and (max-width: 880px) {
  .slider2 {
    .rec.rec-slider-container {
      margin: 0 0 0 0px;
    }
  }
}

@media only screen and (max-width: 980px) {
  .downButtonsTechnology {
    .rec.rec-arrow {
      position: absolute;
      top: auto;
      bottom: 30px;
      width: 37px;
      min-width: 37px;
      height: 28px;
      font-size: 0;
      line-height: inherit;
      z-index: 1;
    }
  }
}

@media only screen and (max-width: 769px) {
  .container {
    width: 90%;
  }

  .topButtons {
    .rec.rec-arrow {
      position: absolute;
      top: -60px;
      width: 37px;
      min-width: 37px;
      height: 28px;
      font-size: 0;
      line-height: inherit;
      z-index: 1;
    }
  }

  .howItWorks {
    .rec.rec-carousel-wrapper {
      background: var(--white);
      position: relative;
      padding: 10px;
    }
  }
}

@media only screen and (max-width: 481px) {
  .container {
    width: 90%;
  }

  .topButtons {
    .rec.rec-arrow {
      max-width: 27px;
      width: 27px;

      &.rec-arrow-left {
        right: 50px;
      }

      &.rec-arrow-right {
        right: 0px;
      }
    }
  }

  .sideButtons {
    .rec.rec-arrow {
      bottom: 40px;

      &.rec-arrow-left {
        left: 20px;
      }

      &.rec-arrow-right {
        right: 20px;
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .slider2 {
    .rec.rec-slider-container {
      margin: 0 0 0 0px;
    }

    .rec.rec-arrow.rec-arrow-left,
    .rec.rec-arrow.rec-arrow-right {
      display: none;
    }

    .rec.rec-carousel-wrapper {
      display: flex;
      flex-direction: column-reverse;
      padding-left: 0px;
    }

    .rec.rec-pagination {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 400px;
      height: 2px;
      background: var(--yellow);
      margin-top: 0;
    }

    .rec.rec-dot {
      position: absolute;
      left: -16px;
      width: 24px;
      height: 24px;
      outline: 5px solid var(--white);
      border: 2px solid var(--general-blue);
      box-sizing: border-box;
      box-shadow: none;
      background: var(--white);

      &.rec-dot_active {
        background: var(--general-blue);
        font-family: var(--font-family-XB);

        &:before {
          display: block;
          position: absolute;
          top: 100px;
          left: -5px;
          font-size: 60px;
          line-height: 45px;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 2px;
          height: 56px;
          background: var(--yellow);
          top: 28px;
          left: 10px;
        }
      }

      &:nth-child(1) {
        top: -15px;
        left: 76px;
        &.rec-dot_active:before {
          content: '1';
        }
      }
      &:nth-child(2) {
        top: -15px;
        left: 151px;

        &.rec-dot_active:before {
          content: '2';
        }
      }

      &:nth-child(3) {
        top: -15px;
        left: 226px;

        &.rec-dot_active:before {
          content: '3';
        }
      }
      &:nth-child(4) {
        top: -15px;
        left: 301px;

        &.rec-dot_active:before {
          content: '4';
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .container {
    width: 300px;
  }
}
