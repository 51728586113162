.sectionSpecificApproach {
  background-color: var(--general-blue);
  font-weight: var(--font-weight-normal);
  font-family: var(--font-family);
  width: 100%;
  padding-top: 37px;
  padding-bottom: 73px;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .title {
    font-size: 60px;
    line-height: 70px;
    color: var(--yellow);
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    margin-bottom: 45px;
    text-align: center;
  }

  .underTitle {
    color: var(--white);
    font-size: var(--font-size-midle);
    font-family: var(--font-family);
    line-height: var(--line-height-xlarge);
    margin-bottom: 43px;
    text-align: center;
  }

  .infoBlocks {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .infoBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 310px;
  }

  .imageBlock {
    width: 185px;
    height: 230px;
    margin-bottom: 47px;
  }

  .titleBlock,
  .textBlock {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-midle);
    line-height: var(--line-height-xlarge);
    line-height: 19px;
  }

  .titleBlock {
    color: var(--yellow);
  }

  .textBlock {
    color: var(--white);
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media only screen and (max-width: 710px) {
  .sectionSpecificApproach {
    .title {
      font-size: 45px;
      line-height: 55px;
    }

    .infoBlock {
      margin-bottom: 60px;
    }
  }
}
