.sectionForm {
  position: relative;

  padding-top: 60px;
  padding-bottom: 80px;

  .imgLayer {
    position: absolute;
    top: 0px;
    left: 0px;

    background-image: url('../../assets/images/form-background.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 100%;
  }

  .whiteLayer {
    position: absolute;

    background-color: rgba(255, 255, 255, 0.4);

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 940px;
  }

  .title {
    font-family: var(--font-family-LT);
    font-size: 40px;
    line-height: 47px;
    font-weight: var(--font-weight-normal);
    color: var(--general-blue);
    margin-bottom: 23px;
    text-align: center;
    text-transform: uppercase;
  }

  .underTitle {
    font-family: var(--font-family-BK);
    font-size: 25px;
    line-height: 27px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: var(--general-blue);
    height: 40px;
    color: var(--white);
    margin-bottom: 15px;
  }

  .quote {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xlarge);
    line-height: 27px;
    text-align: center;
    color: var(--general-blue);
    margin-bottom: 26px;
  }

  .form {
    display: flex;
  }

  .fieldset {
    flex: 1;
    margin: 0;
    padding: 0;
    border: none;

    &.fieldsetleft {
      margin-right: 8px;
    }

    &.fieldsetRigth {
      margin-left: 8px;
    }
  }

  .fieldsetRigth {
    .labelContainer {
      &:nth-of-type(4) {
        margin-top: 18px;
      }
    }
  }

  .labelContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }

  .label {
    font-family: var(--font-family-SB);
    font-size: var(--font-size-small);
    line-height: var(--line-height-midle);
    color: var(--general-blue);
    margin-bottom: 10px;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    span {
      font-size: var(--font-size-small);
      text-transform: uppercase;
      padding-left: 41px;
      font-size: 11px;
      line-height: 13px;
      font-weight: 600;
    }
  }
}

.sendNotification {
  display: flex;
  position: absolute;
  bottom: -70px;
  right: 0;
  padding: 20px 100px;
  border-radius: 5px;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  transition: opacity 1s;

  &.active {
    opacity: 1;
  }

  &.notActive {
    opacity: 0;
  }

  &.resolved {
    background: var(--success);
  }

  &.rejected {
    background: var(--danger);
  }
}

@media screen and (max-width: 1180px) {
  .form {
    display: flex;
  }
}

@media screen and (max-width: 840px) {
  .form {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 481px) {
  .sectionForm .title {
    font-size: 30px;
    line-height: 32px;
  }

  .sectionForm .underTitle {
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 5px;

    height: 63px;
    span {
      width: 50%;
    }
  }

  .sectionForm .quote {
    font-size: 16px;
    line-height: 18px;
  }

  .sectionForm .buttonContainer {
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100px;

    margin-bottom: 50px;

    span {
      width: 100%;
      text-align: left;
    }
  }
}
