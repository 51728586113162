// here are 1 main for all and 3 other state of component.
.main {
  display: flex;
  flex-direction: column;
  background-size: cover;
  background: var(--yellow);
  padding: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin-right: 22%;
  transition: all 0.6s linear;
  overflow: hidden;

  img {
    transition: all 0.6s linear;
  }
}

.notActive {
  width: 22%;
}

.active {
  width: 82%;
  margin-right: 18%;
  background: var(--yellow) url(/assets/images/xtramile_services/background_header.png) no-repeat
  right;
  padding: 10px 30px 150px 130px;
  cursor: default;
  z-index: 1;
}

.hidden {
  margin-right: 6%;
  width: 6%;
  padding: 5px;
  z-index: 2;
  align-items: center;
}

.impact-rh {
  margin-right: 0;
}

.notActive .titleIcon {
  margin-top: 340px;
  height: 150px;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 2rem;

  img {
    width: 75px;
    height: 50px;
  }
}

.active .titleIcon {
  margin-top: 210px;
  height: 150px;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 2rem;

  img {
    width: 113px;
    height: 75px;
  }
}

.hidden .titleIcon {
  margin-top: 0;
  height: 150px;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 2rem;

  img {
    width: 56px;
    height: 38px;
  }
}

.titleForActive {
  transition: opacity 0.6s linear, height 0.6s linear;
  font-family: var(--font-family-XB);
  font-size: 59px;
  line-height: 65px;
}

.notActive .titleForActive,
.hidden .titleForActive {
  height: 0;
  width: 0;
  opacity: 0;
}

.active .titleForActive {
  color: var(--general-blue);
  margin-left: 0;
  opacity: 1;
}

.titleForNotActive {
  font-size: 22px;
  line-height: 27px;
  color: var(--general-blue);
  padding-bottom: 0.5rem;
}

.name {
  font-family: var(--font-family-XB);
  color: var(--white);
  line-height: 27px;
  opacity: 1;
  transition: all 0.6s linear;

  & :nth-child(1) {
    font-size: 40px;
  }

  & :nth-child(2),
  & :nth-child(3) {
    font-size: 27px;
  }
}

.hidden .name {
  opacity: 1;
  display: flex;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  align-items: center;
  font-size: 30px;
  color: var(--general-blue);
  padding-top: 20px;

  & :nth-child(1),
  & :nth-child(2) {
    font-size: 30px;
  }
}

.namePart {
  font-size: 30px;
}

.arrow {
  margin-top: 5px;
}

.nameForActive {
  color: var(--white);
}

.hiddenSpace {
  visibility: hidden;
  font-size: 27px;
}

@media only screen and (max-width: 1350px) {
  .main {
    position: relative;
    width: 100%;
    margin-right: 0px;
    padding: 50px;
    min-height: unset;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: none;
    img {
      transition: none;
    }
  }

  .notActive {
    height: 150px;
  }

  .hidden {
    max-height: 0px;
    padding: 0px;
  }

  .notActive .titleIcon {
    position: absolute;
    left: 40px;
    top: 45px;
    margin-top: 0px;
    height: auto;

    img {
      width: 95px;
      height: 75px;
    }
  }

  .titleForNotActive {
    position: absolute;
    top: 50px;
    left: 25%;
    width: 280px;
  }

  .name {
    position: absolute;
    right: 25%;
    top: 50px;
  }

  .arrow {
    position: absolute;
    right: 60px;
  }

  .active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background: var(--yellow) url(/assets/images/xtramile_services/background_header.png) no-repeat top right;
    padding: auto 130px;
    cursor: default;
    z-index: 1;
  }
}

@media only screen and (max-width: 990px) {
  .main {
    position: relative;
    width: 100%;
    margin-right: 0px;
    padding: 50px;
    min-height: unset;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .notActive {
    height: 200px;
  }

  .name {
    position: absolute;
    right: auto;
    left: 30%;
    top: 110px;
  }

  .notActive .titleIcon {
    top: 65px;

    img {
      width: 95px;
      height: 75px;
    }
  }

  .titleForNotActive {
    position: absolute;
    top: 30px;
    left: 30%;
    width: 280px;
  }

  .hidden {
    max-height: 0px;
    padding: 0px;
  }

  .active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background: var(--yellow) url(/assets/images/xtramile_services/background_header.png) no-repeat top right;
    padding: auto 130px;
    cursor: default;
    z-index: 1;
  }
}

@media only screen and (max-width: 700px) {
  .notActive .titleIcon {
    visibility: hidden;
    width: 0;
  }
  .name {
    left: 10%;
  }

  .titleForNotActive {
    left: 10%;
  }

  .hidden {
    max-height: 0px;
    padding: 0px;
  }

  .active .titleForActive {
    font-size: 40px;
    line-height: 45px;
    color: var(--general-blue);
    margin-left: 0;
    opacity: 1;
  }

  .active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    background: var(--yellow) url(/assets/images/xtramile_services/background_header.png) no-repeat top right;
    padding: 10px 30px 150px 50px;
    cursor: default;
    z-index: 1;
  }

  .name {
    font-family: var(--font-family-XB);
    color: var(--white);
    line-height: 27px;
    opacity: 1;
    transition: all 0.6s linear;

    & :nth-child(1) {
      font-size: 40px;
    }

    & :nth-child(2) {
      font-size: 27px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .notActive .titleIcon {
    visibility: hidden;
    width: 0;
  }
  .name {
    left: 10%;
  }

  .titleForNotActive {
    left: 10%;
  }

  .hidden {
    max-height: 0px;
    padding: 0px;
  }

  .active .titleForActive {
    font-size: 40px;
    line-height: 45px;
    color: var(--general-blue);
    margin-left: 0;
    opacity: 1;
  }

  .active {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--yellow) url(/assets/images/xtramile_services/background_header.png) no-repeat top right;
    padding: 10px 30px 150px 30px;
    cursor: default;
    z-index: 1;
  }

  .name {
    font-family: var(--font-family-XB);
    color: var(--white);
    line-height: 27px;
    opacity: 1;
    transition: all 0.6s linear;

    & :nth-child(1) {
      font-size: 40px;
    }

    & :nth-child(2) {
      font-size: 27px;
    }
  }
}
