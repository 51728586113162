.wrapper {
  position: relative;
}

.carouselWrapper {
  position: relative;
  width: 820px;
  margin-left: 40px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}

.post {
  display: flex;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  background: var(--white);
  width: 820px;
  min-height: 470px;
  padding: 20px;
}

.postLeftPartAva {
  img {
    height: 266px;
    width: 266px;
  }
}

.postLeftPartLogo {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 15px;

  img {
    height: 52px;
    width: 120px;
  }
}

.postRightPart {
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 65px;
  // height: calc(100% - 170px);
}

.postsText {
  display: flex;
  flex-direction: column-reverse;
  min-height: 266px;
  margin-bottom: 20px;

  p {
    font-family: var(--font-family-SBi);
    font-size: 26px;
    line-height: 31px;
  }
}

.postAuthorNameContainer {
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-size: 16px;
  line-height: 21px;
  color: var(--yellow);
}

.clientsTestify {
  display: flex;
  position: relative;
  margin-top: -200px;
  width: 100%;
}

.clientsTestifyTitleWrapper {
  max-width: 330px;
}

.clientsTestifyTitle {
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-size: 60px;
  line-height: 60px;
  color: var(--white);
}

.clientsTestifyButton {
  margin-top: 60px;
}

// team styles
.sectionTeam {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 97px;

  button {
    margin-bottom: -20px;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .topSide {
    position: relative;
    background-color: var(--white);
  }

  .title {
    font-family: var(--font-family);
    font-size: 60px;
    line-height: 70px;
    text-align: center;
    font-weight: var(--font-weight-bold);
    color: var(--general-blue);
    margin-bottom: 36px;
  }

  .description {
    font-family: var(--font-family);
    font-size: var(--font-size-midle);
    line-height: var(--line-height-xlarge);
    max-width: 940px;
    text-align: center;
    margin: 0 auto 8px;
    padding: 0 14px 20px;
  }

  .mainTarget {
    font-family: var(--font-family);
    font-size: var(--font-size-midle);
    font-weight: var(--font-weight-bold);
    line-height: var(--line-height-xlarge);
    max-width: 940px;
    text-align: center;
    margin: 0 auto 40px;
  }

  .teamImage {
    width: 700px;
    height: 670px;
    margin: 0 auto -185px;
    background-image: url('../../assets/images/team/team.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bottomSide {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--yellow);
    padding: 210px 40px 40px 40px;
    // padding-top: 210px;
  }

  .directExpertise {
    font-family: var(--font-family-LT);
    font-size: 40px;
    line-height: 50px;
    font-weight: var(--font-weight-normal);
    color: var(--white);
    text-align: center;
    margin-bottom: 37px;
  }

  .directions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    // min-width: 980px;
    margin: 0;

    div {
      margin-bottom: 50px;
    }

    p {
      font-size: var(--font-size-small);
      line-height: 19px;
    }

    [data-title] {
      line-height: 2;
    }
    [data-text] {
      line-height: 1.5;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .wrapper {
    height: auto;
    flex-direction: column;
  }

  .clientsTestify {
    flex-direction: column;
  }

  .carouselWrapper {
    width: 90%;

    margin-left: 5%;
    margin-top: 50px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.1);
  }

  .post {
    width: 100%;
  }
}

@media only screen and (max-width: 980px) {
  .post {
    flex-direction: column;
    align-items: center;
  }

  .postLeftPartLogo {
    justify-content: center;
  }
}

@media only screen and (max-width: 750px) {
  .sectionTeam {
    .teamImage {
      width: 100%;
      height: 300px;
      background-size: contain;
    }
  }
}

@media only screen and (max-width: 680px) {
  .wrapper {
    height: auto;
    flex-direction: column;
  }

  .carouselWrapper {
    padding-bottom: 60px;
  }

  // .clientsTestify {
  //   height: 500px;
  //   width: 90%;
  //   padding-right: 50px;
  // }

  .clientsTestifyTitle {
    font-size: 36px;
    line-height: 41px;
  }

  .post {
    width: 100%;
  }

  .postRightPart {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }

  .postsText {
    display: flex;
    flex-direction: column-reverse;
    min-height: 266px;
    margin-bottom: 20px;

    p {
      font-family: var(--font-family-SBi);
      font-size: 21px;
      line-height: 26px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .clientsTestify {
    flex-direction: column;
    align-items: center;
  }

  .clientsTestifyTitleWrapper {
    max-width: 100%;
  }
  .clientsTestifyTitle {
    font-size: 36px;
    text-align: center;
  }

  .clientsTestifyButton {
    display: flex;
    justify-content: center;
  }

  .sectionTeam {
    .directions {
      justify-content: center;
    }
  }
}
