p {
  font-size: var(--font-size-small);
  line-height: --line-height-large;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 219px;
  height: 244px;

  p {
    text-align: center;
  }
}

.blockTitle {
  font-family: var(--font-family-XB);
}

.blockIcon {
  width: 100px;
  height: 100px;
  margin-bottom: 7px;
}

.blockText {
  font-family: var(--font-family-SB);
  font-size: var(--line-height-small);
  line-height: 19px;

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 500px) {
  .block {
    width: 159px;
    height: auto;
    margin-bottom: 30px;
  }
}
