.teamSection {
  display: flex;
  margin-top: 100px;
  min-height: 550px;
  width: 100%;

  .greyRectangle {
    width: 820px;
    height: 385px;

    padding-top: 70px;
    padding-right: 75px;
    padding-left: 350px;
    margin-left: -200px;

    margin-top: 280px;
    background-color: rgba(32, 31, 53, 0.2);
  }

  .greyRectangleTextSection {
    font-family: var(--font-family-LT);
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: var(--general-blue);
    width: 354px;
  }

  .teamPictureContainer {
    width: 700px;
    height: 655px;
    padding: 0%;

    margin-right: -100px;
    background-image: url('../../assets/images/team/team.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ourValues {
  display: flex;

  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 1144px;
  padding-top: 50px;
}

.title {
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-style: normal;
  font-weight: 500;
  font-size: 70px;
  word-break: normal;
  line-height: 70px;
  color: #e0bc37;

  margin-top: 0px;
  margin-bottom: 60px;
}

.text {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.rowForIcons {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.iconItems {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-XB);
  align-items: center;

  .imgItem {
    margin-bottom: 10px;
  }
}

.teamTreeSection {
  position: relative;
  display: flex;
  margin-top: 120px;
  align-items: stretch;
  min-height: 1600px;
  width: 100%;
}

.blueRectangle {
  top: 0px;
  left: 0px;
  min-width: 750px;
  align-self: stretch;

  background-color: var(--general-blue);
}

.overBlueRectangle {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0px;
  left: -750px;
  padding: 60px;
  padding-left: 90px;
  width: 100%;
}

.subtitle {
  font-style: normal;
  text-transform: uppercase;

  font-family: var(--font-family-LT);
  font-weight: 500;
  font-size: 25px;
  line-height: 50px;
  width: 600px;
  color: #fff;
  margin-top: 100px;
}

.subtitleSecondLine {
  font-style: normal;
  text-transform: uppercase;

  font-family: var(--font-family-LT);
  font-weight: 500;
  font-size: 25px;
  line-height: 50px;
  width: 600px;
  color: #fff;
}

.subtitleContainer {
  width: 250px;
}

.rowForEmployees {
  display: flex;
  flex-direction: row;
  min-height: 340px;
}

.rowOfCard {
  display: flex;
  flex-direction: row;
  width: 1800px;
  overflow-x: auto;
  padding-bottom: 30px;
}

.ourExpertsSection {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
}

.ourExperts {
  display: flex;
  flex-direction: column;
  margin-top: -100px;
  padding: 120px;
  padding-top: 150px;

  width: 1300px;
  background-color: #e0bc37;
  min-height: 1115px;
  text-align: center;

  .title {
    color: #fff;
  }

  .subtitleForExperts {
    color: var(--general-blue);
    font-family: var(--font-family);

    font-size: 16px;
    font-weight: 500;
    margin-top: 60px;
    margin-bottom: 40px;
  }
}

.blockByExpertise {
  display: flex;
  flex-direction: row;

  margin-top: 20px;

  &.right {
    justify-content: flex-end;
  }
}

.blockOfTextByExpertise {
  display: flex;
  flex-direction: column;
  width: 500px;
  padding-top: 0px;

  font-family: var(--font-family-SB);
  font-size: 16px;
  font-weight: 500;

  &.right {
    text-align: right;
    align-items: flex-end;
  }

  &.left {
    text-align: left;
    align-items: flex-start;
  }

  .titleBlockOfExpertise {
    font-family: var(--font-family-LT);
    font-size: 40px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}

.iconByExpertise {
  height: 125px;
  width: 125px;

  margin: 20px;
}

@media only screen and (max-width: 1180px) {
  .teamSection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;

    .greyRectangle {
      width: 90%;
      height: auto;
      margin-left: 0px;
      padding: 70px;
      padding-top: 150px;
    }

    .greyRectangleTextSection {
      font-family: var(--font-family-LT);
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      color: var(--general-blue);
      width: 100%;
    }

    .teamPictureContainer {
      width: 100%;
      background-size: contain;
      margin-bottom: -190px;
      margin-right: -20px;
    }
  }

  .title {
    margin-bottom: 50px;
  }

  .subtitle {
    margin-top: 50px;
  }

  .rowForEmployees {
    flex-direction: column;
  }

  .rowOfCard {
    overflow-y: hidden;
    width: 100%;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 920px) {
  .centerContent {
    margin-top: 50px;
  }

  .ourValues {
    width: 90%;
  }
}

@media only screen and (max-width: 880px) {
  .blueRectangle {
    min-width: 100%;
  }

  .overBlueRectangle {
    background-color: var(--general-blue);
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 0px;
  }

  .rowOfCard {
    min-height: 0px;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 630px) {
  .teamSection {
    .greyRectangleTextSection {
      font-size: 18px;
      line-height: 23px;
    }

    .teamPictureContainer {
      margin-bottom: -230px;
    }
  }

  .title {
    font-size: 45px;
    line-height: 48px;
    margin-bottom: 50px;
  }

  .rowForIcons {
    .iconItems {
      flex: 0 0 35%;
    }
  }

  .overBlueRectangle {
    padding-left: 50px;
  }

  .ourExperts {
    width: 100%;
    padding: 120px 20px;

    .subtitleForExperts {
      margin-top: 10px;
    }
  }

  .blockOfTextByExpertise {
    width: 100%;

    font-size: 14px;
    .titleBlockOfExpertise {
      font-family: var(--font-family-LT);
      font-size: 24px;
      color: #fff;
      font-weight: 500;
      margin-bottom: 20px;
      text-transform: uppercase;
    }
  }

  .iconByExpertise {
    height: 30px;
    width: 30px;

    margin: 0px 20px 20px 20px;
  }
}

@media only screen and (max-width: 540px) {
  .subtitleContainer {
    width: 100%;
  }

  .subtitle {
    text-align: center;
    font-size: 30px;
    width: 100%;
  }

  .rowForEmployees {
    width: 100%;
  }

  .rowOfCard {
    justify-self: center;
    width: 100%;
    margin-left: 0px;
    justify-content: center;
    overflow-x: hidden;
  }

  .overBlueRectangle {
    padding-left: 0px;
    align-self: center;
  }
}

@media only screen and (max-width: 420px) {
  .teamSection {
    margin-top: -50px;

    .greyRectangle {
      padding: 30px;
      padding-top: 180px;
    }

    .greyRectangleTextSection {
      font-size: 18px;
      line-height: 23px;
    }

    .teamPictureContainer {
      margin-bottom: -330px;
    }
  }

  .title {
    font-size: 36px;
    line-height: 41px;
    margin-bottom: 50px;
  }

  .text {
    font-size: 16px;
  }

  .overBlueRectangle {
    padding-left: 0px;
  }

  .rowOfCard {
    width: 100%;
    // margin-left: 150px;
    justify-content: center;
  }
}
