.carouselWrapper {
  position: relative;
}

.post {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 60px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  background: var(--white);
  width: 580px;
  height: 420px;

  .videoContainer {
    height: 326px;
    width: 580px;
  }
}

.postsText {
  overflow: visible;
}

.videoName {
  position: absolute;

  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  line-height: 23px;

  bottom: 60px;
  left: 40px;
  color: var(--general-blue);
}

.subtitleName {
  position: absolute;
  bottom: 30px;
  left: 40px;
  margin-top: 0px;
  font-family: var(--font-family-BK);
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;

  color: var(--general-blue);
}

.postsLink {
  display: inline-flex;
  width: fit-content;
  position: absolute;
  bottom: 30px;
  right: 30px;

  &:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1180px) {
  .carouselWrapper {
    display: flex;
    justify-content: center;
  }

  .post {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 36px;
    // margin-right: ;

    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
    background: var(--white);
    width: 580px;
    height: 350px;

    .videoContainer {
      height: 280px;
      width: 100%;
    }
  }

  .videoName {
    position: absolute;
    bottom: 30px;
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    font-size: 16px;
    line-height: 23px;

    color: var(--general-blue);
  }

  .subtitleName {
    bottom: 10px;
  }
}

@media only screen and (max-width: 782px) {
  .post {
    display: flex;
    flex-direction: column;

    width: 85%;
    height: 350px;

    margin-bottom: 36px;

    .videoContainer {
      width: 100%;
      height: 280px;
    }
  }

  .videoName {
    left: 15px;
    position: absolute;
    bottom: 30px;
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    font-size: 16px;
    line-height: 23px;

    color: var(--general-blue);
  }

  .subtitleName {
    bottom: 10px;
    left: 15px;
  }

  .carouselWrapper {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 480px) {
  .post {
    display: flex;
    flex-direction: column;

    max-height: 250px;
    width: 80%;
    margin-bottom: 36px;

    .videoContainer {
      width: 100%;
      height: 180px;
    }
  }

  .videoName {
    font-size: 14px;
    bottom: 35px;
  }

  .subtitleName {
    font-size: 12px;
    // width: 20ch;
    line-height: 17px;
  }
}
