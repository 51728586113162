.headerDropdown {
  position: absolute;
  top: 49px;
  right: 0;
  z-index: 3;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 97vh;

  padding: 16px;
  padding-bottom: 32px;
  background-color: var(--yellow);
}

.headerBaseline {
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
}

.headerDropdownList {
  margin: 18px 0;
  list-style: none;
}

.headerDropdownElem {
  &:nth-child(2),
  &:nth-child(3) {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
  }
}

.headerDropdownLink {
  display: block;
  position: relative;
  font-size: 25px;
  line-height: 28px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 11px 16px;

  &:after {
    display: block;
    content: ' ';
    position: absolute;
    bottom: -3px;
    margin-left: 50%;
    width: 0;
    height: 3px;
    background-color: var(--general-blue);
    transition: all 0.3s linear 0s;
    transform: translateY(-50%);
  }

  &:hover {
    color: var(--general-blue);
    text-decoration: none;

    &:after {
      width: 100%;
      margin-left: 0;
      left: 0;
    }
  }
}

.bottomRow {
  display: flex;
  justify-content: space-around;
  align-items: center;

  width: 100%;

  margin-top: 30px;
}

.customerAccess {
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    height: 40px;
    width: auto;
    margin-right: 8px;
  }
}

.networkPanel {
  display: flex;
  justify-content: space-between;
  width: 60%;

  list-style: none;
  padding: 0;

  li {
    transition: transform 0.15s ease-in;
    transform: translateY(0px);

    &:hover {
      transition: transform 0.15s ease-in;
      transform: translateY(-5px);
    }
  }
}

.netIcons {
  img {
    width: 31px;
    height: 31px;
  }
}
