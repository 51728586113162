.section {
  padding-top: 85px;

  .container {
    display: flex;
    flex-direction: column;
  }

  .ourCustomersTestify {
    display: flex;
    flex-direction: column;
    background-color: var(--yellow);
    padding: 50px 20px 35px 20px;
    margin-bottom: 80px;
  }

  .ourCustomersTestifyTitle {
    margin-bottom: 50px;
    text-transform: uppercase;
    font-family: var(--font-family-LT);
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: var(--general-blue);
  }
}
