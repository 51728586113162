.carouselWrapper {
  position: relative;
}

.post {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 60px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  background: var(--white);
  width: 340px;
  height: 400px;

  img {
    height: auto;
    width: 100%;
  }
}

.postsText {
  padding: 25px 30px 0 30px;
  overflow: hidden;
}

.newsText {
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-size: 18px;
  line-height: 23px;
  overflow: hidden;
}

.postsLink {
  display: inline-flex;
  width: fit-content;
  position: absolute;
  bottom: 30px;

  &:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 400px) {
  .post {
    width: 90%;
  }
}
