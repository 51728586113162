.section {
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-normal);
  width: 100%;
  position: relative;
  background: var(--white);

  .sectionContent {
    display: flex;
    justify-content: space-between;
    position: relative;

    z-index: 2;
    transform: translate(calc(100vw / 2 - (var(--max-width) / 2)), calc(-50%));
    width: var(--max-width);
    padding: 40px 40px 40px 90px;
    background: var(--white);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  }

  .imgWrapper {
    display: flex;
    position: absolute;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    left: -66px;
    top: calc(50% - 65px);
    background: var(--white);

    img {
      display: flex;
      width: 94px;
      height: 78px;
      margin: auto;
    }
  }

  .text {
    font-family: var(--font-family-LT);
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    max-width: 640px;

    & :nth-child(2) {
      font-family: var(--font-family-XB);
      color: var(--yellow);
    }
  }

  .slider {
    min-width: 320px;
  }

  .slide {
    padding: 0 10px;

    .slidersHeader {
      font-family: var(--font-family);
      font-weight: var(--font-weight-bold);
      font-size: 22px;
      line-height: 32px;

      .textNumber {
        font-family: var(--font-family-LT);
        font-size: 60px;
        line-height: 70px;
        color: var(--yellow);
      }
    }
  }

  .sectionWrapperButtonParse {
    display: flex;
    justify-content: center;
    margin-bottom: 130px;
    margin-top: -70px;
  }
}

@media only screen and (max-width: 1350px) {
  .section {
    .sectionContent {
      transform: translate(calc(100vw / 2 - (var(--max-width) / 2)), 0);
    }

    .sectionWrapperButtonParse {
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 1180px) {
  .section {
    align-items: center;

    .sectionContent {
      max-width: 90%;
      transform: unset;

      flex-direction: column;
      padding: 40px;
      padding-top: 100px;

      .imgWrapper {
        position: absolute;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        left: calc(50% - 65px);
        top: -65px;
        background: var(--white);

        img {
          width: 94px;
          height: 78px;
        }
      }

      .text {
        width: 100%;
        max-width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}
