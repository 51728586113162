.customButton {
  min-width: 140px;
  min-height: 40px;
  padding: 12px 38px;
  border-radius: 4px;
  background: var(--general-blue);
  color: var(--white);
  cursor: pointer;
  text-transform: uppercase;
  transition: background 0.4s;
  border: 1px solid var(--general-blue);

  &:hover {
    background: var(--general-blue-light);
  }

  &:active {
    background: var(--black);
  }
}
