.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.header {
  // position: fixed;
  // top: 0;
  // left: 0;
  z-index: 4;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 50px;
  color: var(--general-blue);
  padding: 12px 50px;

  & :nth-child(1),
  & :nth-child(2) {
    display: flex;
    align-items: center;
  }
}

.logo {
  img {
    width: 250px;
    height: 130px;
    margin-top: -70px;
    margin-bottom: -70px;
    margin-left: -40px;
    margin-right: -50px;
  }
}

.text {
  display: flex;
  align-self: flex-end;
  padding: 0 25px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  //line-height: 21px;
  line-height: 13px;
}

.texLink {
  display: flex;
  padding: 0 25px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: var(--font-weight-bold);
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--yellow);
  opacity: 0.75;
  mix-blend-mode: normal;
  text-transform: uppercase;
  text-decoration: none;

  img {
    margin-right: 10px;
  }
}

.menuContainer {
  display: flex;
  margin-left: 15px;
  font-size: 12px;
  line-height: 14px;
  color: var(--general-blue);
  cursor: pointer;
}

.menu,
.menuActive {
  font-family: var(--font-family-BK);
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  opacity: 0.75;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
}

.menu {
  color: var(--general-blue);
}

.menuActive {
  color: #2a3260;
}

.burgerMenu {
  img {
    width: 30px;
    height: 17px;
    margin-left: 18px;
  }
}

@media screen and (max-width: 1180px) {
}

@media screen and (max-width: 782px) {
}

@media screen and (max-width: 480px) {
  .header {
    padding: 0px 20px;
  }
  .texLink {
    padding-right: 0px;
  }
}

@media screen and (max-width: 370px) {
}
