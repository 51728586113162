.wrapperDialogParse {
  position: fixed;
  z-index: 300;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  overflow: auto;
}

.dialogParse {
  background-color: #fefefe;
  margin: 7% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 60%;
}

.dialogClose {
  display: flex;
  justify-content: flex-end;
  color: var(--general-blue);
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.dialogTitle {
  text-align: center;
  font-family: var(--font-family-SB);
}

.dialogSubtitle {
  text-align: center;
  font-family: var(--font-family-SB);
  font-size: 16px;
  line-height: 24px;
}
