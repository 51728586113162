.section {
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-normal);
  width: 100%;
  position: relative;
  max-width: var(--max-width);
  margin: -100px auto 0 auto;
  padding: 20px 90px;

  .post {
    padding: 0 10px;
    height: 450px;

    &.post0 {
      padding-top: 45px;
    }

    &.post1 {
      padding-top: 120px;
    }

    &.post2 {
      padding-top: 195px;
    }

    &.post3 {
      padding-top: 270px;
    }
  }

  .slidersHeader {
    font-family: var(--font-family-SB);
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 40px;
  }

  .title {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    font-size: 40px;
    line-height: 45px;
    color: var(--yellow);
  }

  .text {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 26px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1180px) {
  .section {
    // padding: 20px 20px;
    margin: 50px auto 0 auto;

    .post {
      // height: auto;

      &.post0 {
        padding-top: 150px;
      }

      &.post1 {
        padding-top: 150px;
      }

      &.post2 {
        padding-top: 150px;
      }

      &.post3 {
        padding-top: 150px;
      }
    }
  }
}

@media only screen and (max-width: 880px) {
  .section {
    padding: 20px 20px;

    .post {
      height: auto;
    }
  }
}

@media only screen and (max-width: 580px) {
  .section {
    .post {
      .title {
        font-size: 30px;
        line-height: 35px;
      }

      .text {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
