.inputLabel {
  font-weight: var(--font-weight-bold);
  color: var(--general-blue);
  margin-bottom: 10px;
}

.inputField {
  border: none;
  height: 40px;
  border-bottom: 5px solid var(--general-blue);
  padding: 7px 16px;

  &:focus {
    outline: none;
  }

  &.error {
    border-bottom: 5px solid var(--danger);
    transition: border-bottom-color 0.4s;
  }
}

.errorMessage {
  height: 7px;
  font-size: 14px;
  color: var(--danger);
  opacity: 0;
  transition: opacity 0.4s;

  &.active {
    opacity: 1;
  }
}

.checkboxContainer {
  display: flex;
  padding-top: 32px;
}

.checkboxInput,
.checkboxInputChecked {
  background-color: var(--white);
  //margin-top: 32px;
  min-width: 25px;
  height: 25px;
  border: 2px solid var(--general-blue);
}

.checkboxInputChecked {
  &:before {
    content: '\2713';
    display: inline-block;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    background-color: var(--white);
    margin-right: 6px;
    vertical-align: baseline;
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 18px;
    line-height: 20px;
    color: var(--general-blue);
  }
}

.checkboxLabel {
  //margin-top: 32px;
  margin-left: 16px;
  color: var(--general-blue);

  p {
    margin-bottom: 16px;
  }
}

.checkboxLabelFirst {
  font-family: var(--font-family-SB);
}

.checkboxLabelSecond {
  font-family: var(--font-family-BK);
}
