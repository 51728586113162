.langsNavBar {
  & > ul {
    display: flex;
    list-style: none;
  }
}

.lang,
.activeLang {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  opacity: 0.75;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
}

.lang {
  color: var(--yellow);
}

.activeLang {
  color: #2a3260;
}
