.initialUploader {
  padding: 30px;
}

.textBefore {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 0 50px 0;

  .title {
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    padding-bottom: 24px;
    margin: 0;
  }

  .titleText {
    font-size: 16px;
    line-height: 21px;
    margin: 0;
    text-align: center;
  }
}

.dropzoneContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 700px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 25px 25px;
  background: rgba(255, 255, 255, 0.5);
  border: 1px dashed var(--yellow);
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  transition: border 0.24s ease-in-out;

  &:hover {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.6);
  }
}

.filesList {
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 0;
  max-height: 400px;
  overflow-y: auto;
}

.li {
  list-style: none;
}

.li:not(:last-child) {
  border-bottom: 1px solid var(--gray-C4);
}

.dataAgreement {
  padding: 15px 15px 5px 15px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  transition: border 0.24s ease-in-out;

  .dataAgreementText {
    display: flex;
    align-items: center;
  }

  .checbox {
    background: rgba(0, 0, 0, 0.6);
  }

  label {
    font-size: 14px;
    line-height: 21px;
    padding-left: 35px;
  }
}

.saveSpaceBlock {
  height: 10px;
  width: 100px;
}

.dataAgreementErr {
  padding-left: 35px;
  font-size: 12px;
  line-height: 18px;
  color: var(--error);
}

.actionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  background: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.mainError {
  display: flex;
  flex-direction: column;
  padding: 25px 0 10px 0;
  color: var(--error);
}

.mainErrorTitle {
  display: flex;
  padding-bottom: 33px;
  margin: auto;
  font-size: 30px;
  font-weight: 700;
  line-height: 46px;
  text-transform: uppercase;

  svg {
    margin: auto 24px auto 0;
  }
}

.mainErrorText {
  display: flex;
  margin: auto;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
}

.loaderContainer {
  display: flex;
  align-items: center;
  padding: 35px 0;
}

.loaderText {
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 46px;
  padding-left: 44px;
  font-weight: bold;
  color: var(--general-blue);
}

.uploaderLabel {
  display: block;
}

.uploaderLabelPart1 {
  display: flex;
}

.uploaderIcon {
  display: inline-flex;
  margin: 0 15px 3px 0;
}

.selectFile {
  display: flex;
  flex-wrap: wrap;
  align-self: flex-end;
  font-size: 20px;
  line-height: 30px;
}

.allowedFormats {
  font-size: 16px;
  line-height: 24px;
  margin-left: 53px;
}

.button {
  margin: 60px auto 10px auto;
  border: 2px solid var(--yellow);
  box-sizing: border-box;
  border-radius: 30px;
  padding: 17px 35px;
  min-width: 280px;

  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  background-color: var(--yellow);

  &:hover {
    color: var(--yellow);
    background-color: var(--white);
    border-color: var(--yellow);
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:active {
    background-color: var(--yellow) !important;
    border-color: var(--yellow) !important;
    color: var(--white) !important;

    &:focus {
      box-shadow: none !important;
      outline: none;
    }
  }

  &:disabled {
    &,
    &:hover {
      color: var(--white);
      background-color: var(--yellow);
      border-color: var(--yellow);
      cursor: auto;
    }
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px 0 10px 0;
  min-height: 80px;
}

.orangeLink {
  color: var(--yellow);

  &:hover {
    text-decoration: none;
  }
}

.wrapperButtonParse {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
