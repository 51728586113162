.productsTwoByTwo {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
}

.cardsContainer {
  display: flex;
  flex-direction: row;

  height: 250px;

  .card {
    position: relative;

    width: 460px;
    height: 220px;

    margin: 15px;
    margin-top: 70px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.1);
    }

    .baseline {
      position: absolute;

      left: 158px;
      top: 31px;

      font-family: var(--font-family);

      font-size: 22px;
      font-weight: 500;

      width: 60%;

      &.white {
        color: var(--white);
      }
    }

    .product {
      position: absolute;
      width: 200px;

      left: 160px;
      top: 95px;

      & .big {
        font-family: var(--font-family-XB);
        font-size: 30px;
        font-weight: 500;
      }

      & .small {
        top: 135px;
        font-family: var(--font-family-XB);
        font-size: 20px;
        font-weight: 500;
      }

      & .white {
        color: var(--white);
      }

      & .yellow {
        color: var(--yellow);
      }
    }

    .arrow {
      position: absolute;

      right: 40px;
      top: 130px;

      width: 36px;
      height: 30px;

      &.white {
        color: var(--white);
      }

      &.yellow  {
        color: var(--yellow);
      }

      cursor: pointer;
    }

    &.white {
      background-color: var(--general-blue);

      .icon {
        position: absolute;
        left: 46px;
        top: 48px;

        width: 73px;
        height: 94px;
      }
    }

    &.yellow {
      background-color: var(--yellow);

      .icon {
        position: absolute;
        left: 25px;
        top: 63px;

        width: 114px;
        height: 77px;
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .cardsContainer {
    flex-direction: column;
    height: auto;
    margin-bottom: 50px;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 500px) {
  .cardsContainer {
    margin-top: 80px;
    .card {
      margin-top: 10px;
      margin-bottom: 10px;
      width: 312px !important;
      height: 312px !important;

      .baseline {
        width: 40%;
      }

      .product {
        left: 40px;
        top: 195px;

        & .small {
          top: 235px;
        }
      }

      .arrow {
        right: 30px;
        top: 210px;
      }
    }
  }
}
