.dataCollapseContainer {
  width: 800px;
  margin: auto;
}

.dataCollapseTitle {
  margin: 45px auto 40px auto;
  font-weight: 500;
  font-size: 40px;
  line-height: 47px;
  text-align: center;
  text-transform: uppercase;
}

.dataSection {
  padding-bottom: 30px;
}

.dataBlock {
  border: 1px solid var(--general-blue);
  border-radius: 4px;
  overflow: hidden;
}

.titleOfBlock {
  padding: 0 23px 10px 23px;
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--black-real);
  text-transform: capitalize;
}

.dataTitleContainer {
  display: flex;
  padding: 10px 23px;
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--white);
  background: var(--general-blue);
  cursor: pointer;
  text-transform: capitalize;
  transition-property: background-color, color;
  transition-duration: 0.4s;
}

.activeArrow {
  display: flex;
  align-items: center;
  padding-left: 5px;
  transition: all 0.6s ease;
}

.notActiveArrow {
  display: flex;
  align-items: center;
  padding-right: 5px;
  transform: rotateZ(-180deg);
  transition: all 0.6s ease;
}

.activeBlock {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: auto;
  max-height: 1000px;
  border-radius: 0 0 4px 4px;
  transition: all 0.6s ease;
}

.notActiveBlock {
  display: flex;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: all 0.6s ease;
}

.partOfData {
  margin-bottom: 1px;
  border-top: 1px solid var(--general-blue);
  border-bottom: 1px solid var(--general-blue);

  &:last-child {
    border-bottom: none;
  }
}

.dataItem {
  display: flex;
  padding: 10px 23px;
  width: 100%;
  border-top: 1px solid var(--general-blue);
  font-size: 20px;
  line-height: 30px;
  background: var(--general-blue);
  font-weight: 600;
  color: var(--black-real);
  background: transparent;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: none;
  }
}

.itemTitle {
  width: 100%;
  overflow: hidden;
  margin-right: 5px;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.itemKey {
  width: 250px;
  overflow: hidden;
  margin-right: 5px;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.itemValue {
  width: calc(100% - 300px);
  font-weight: normal;
}

.phoneNumber {
  display: block;
}

.buttonsContainer {
  margin: 30px auto 45px auto;
  width: fit-content;

  button {
    margin: 15px;
    padding: 12px;
    min-width: 150px;
  }
}

.noDataMessage {
  margin: 15px auto;
  padding: 15px;
}

@media only screen and (max-width: 750px) {
  .dataCollapseContainer {
    width: 100%;
    padding: 0 20px;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .itemKey {
    width: 40%;
  }

  .itemValue {
    width: 60%;
  }
}
