/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  cursor: pointer;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create uploaderPage custom checkbox */
.checkmark {
  position: absolute;
  top: calc(50% - 11px);
  left: 0;
  height: 22px;
  width: 22px;
  background: transparent;
  border: 2px solid rgba(0, 0, 0, 0.6);
  border-radius: 4px;
}

/* On mouse-over, add uploaderPage grey background color */
.container:hover input ~ .checkmark {
  border: 2px solid var(--general-blue);
}

/* When the checkbox is checked, add uploaderPage blue background */
.container input:checked ~ .checkmark {
  background-color: var(--general-blue);
  border: 2px solid transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.orangeLink {
  color: var(--yellow);
}
