.dataCollapseContainer {
  margin: auto;
}

.dataCollapseText {
  font-size: var(--font-size-large);
  line-height: 1.5;
  text-align: justify;
  text-indent: 1.5rem;
  padding: 0 20px;
}

.dataCollapseLevelValue {
  font-weight: var(--font-weight-bold);
}

.dataTitleContainer {
  display: flex;
  padding: 10px 23px;
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--white);
  background: var(--general-blue);
  cursor: pointer;
  text-transform: capitalize;
  transition-property: background-color, color;
  transition-duration: 0.4s;
}

.buttonsContainer {
  margin: 30px auto 45px auto;
  width: fit-content;

  button {
    margin: 15px;
    padding: 12px;
    min-width: 150px;
  }
}

@media only screen and (max-width: 750px) {
  .dataCollapseContainer {
    width: 100%;
    padding: 0 20px;
  }

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
