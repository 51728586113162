.card {
  position: relative;
  min-width: 205px;
  height: 360px;
  background: white;
  margin-right: 30px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);

  &.expanded {
    min-width: 700px;
  }
}

.photo {
  position: absolute;
  width: 165px;
  height: 210px;
  top: 20px;
  left: 20px;
  transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);

  &.expanded {
    width: 80px;
    height: 80px;
    top: 30px;
    left: 30px;
  }
}

.name {
  position: absolute;
  top: 240px;
  left: 20px;

  font-family: var(--font-family-XB);
  font-style: normal;
  font-size: 18px;
  font-weight: 500px;
  width: 180px;

  color: var(--general-blue);

  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &.expanded {
    font-size: 22px;
    width: 400px;
    top: 60px;
    left: 136px;
  }
}

.title {
  position: absolute;
  top: 270px;
  left: 20px;
  font-family: var(--font-family-XB);
  font-style: normal;
  font-size: 18px;
  font-weight: 500px;
  width: 160px;
  word-wrap: normal;

  color: #e0bc37;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &.expanded {
    font-size: 22px;
    width: 400px;
    top: 85px;
    left: 136px;
  }
}

.linkedin {
  position: absolute;
  top: 320px;
  left: 20px;

  width: 30px;
  height: 30px;
  cursor: pointer;

  &.expanded {
    top: 30px;
    left: 136px;
  }
}

.yellowArrow {
  position: absolute;
  top: 320px;
  right: 20px;

  width: 40px;
  height: 30px;
  display: inline;
  cursor: pointer;
  &.expanded {
    display: none;
  }
}

.closeButton {
  position: absolute;
  top: 30px;
  right: 20px;

  width: 40px;
  height: 30px;

  display: none;
  opacity: 0;
  cursor: pointer;

  &.expanded {
    opacity: 1;
    display: inline;
  }
}

.description {
  position: absolute;
  top: 134px;
  left: 30px;
  right: 30px;

  font-family: var(--font-family);
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  white-space: pre-wrap;
  word-wrap: break-word;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-delay: -1s;
  height: 200px;

  overflow-y: auto;
  visibility: hidden;

  &.expanded {
    transition-delay: 0.3s;
    opacity: 1;
    width: 640px;
    visibility: visible;
  }
}

@media only screen and (max-width: 880px) {
  .card {
    margin-top: 20px;

    &.expanded {
      min-width: 90%;
      min-height: 500px;
    }
  }

  .description {
    height: 300px;
    top: 154px;
    &.expanded {
      right: 20px;
      line-height: 18px;
      width: 85%;
    }
  }
}

@media only screen and (max-width: 520px) {
  .card {
    margin-right: 0px;
    margin-left: 30px;
    &.expanded {
      min-width: 90%;

      min-height: 550px;
    }
  }

  .description {
    height: 350px;
  }
  .title {
    &.expanded {
      font-size: 16.5px;
      top: 90px;
    }
  }

  .closeButton {
    position: absolute;
    top: 30px;
    right: 20px;

    width: 30px;
    height: 20px;

    display: none;
    opacity: 0;
    cursor: pointer;

    &.expanded {
      opacity: 1;
      display: inline;
    }
  }
}

@media only screen and (max-width: 420px) {
  .name {
    &.expanded {
      width: 18ch;
    }
  }

  .title {
    &.expanded {
      width: 18ch;
    }
  }

  .card {
    margin: 0px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 350px) {
  .name {
    &.expanded {
      width: 12ch;
    }
  }

  .title {
    &.expanded {
      width: 12ch;
      top: 120px;
    }
  }

  .description {
    top: 170px;
  }
}
