.sectionNews {
  display: flex;
  justify-content: flex-end;
}

.wrapper {
  width: 1550px;
  display: flex;
  justify-content: center;
  background: var(--general-blue);
  padding-top: 21px;
  padding-bottom: 80px;
  margin-top: 80px;
}

.yellow {
  background: var(--yellow);
}
.blue {
  background: var(--general-blue);
}

.news {
  display: flex;
  flex-direction: column;
  width: 1180px;
}

.newsTitleContainer {
  display: flex;

  justify-content: flex-start;
}

.title {
  margin: 30px 30px;
  padding-bottom: 35px;
  font-family: var(--font-family);
  font-size: 60px;
  line-height: 70px;
  font-weight: var(--font-weight-bold);
  color: var(--yellow);
}

.seeEverythingButton {
  margin: 40px 0 0 auto;
}

@media screen and (max-width: 1180px) {
  .wrapper {
    width: 100%;
  }

  .news {
    width: 100%;
  }

  .title {
    padding-bottom: 0px;
    margin: 20px 20px;
  }
}

@media screen and (max-width: 782px) {
  .wrapper {
    width: 100%;
  }

  .news {
    width: 100%;
  }

  .title {
    font-size: 36px;
    padding-bottom: 0px;
    margin: 20px 20px;
  }
}

@media only screen and (max-width: 481px) {
  .news {
    width: 100%;
  }

  .title {
    font-size: 36px;
    margin: 10px 0px;
  }
}
