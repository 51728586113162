.sectionTechnologicalApproach {
  background-color: var(--general-blue);
  height: 1200px;
  font-family: var(--font-family);

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    align-items: center;

    .title {
      font-family: var(--font-family);
      font-weight: var(--font-weight-bold);
      font-size: 60px;

      color: var(--yellow);
      margin-top: 40px;
    }

    .description {
      font-size: 16px;
      margin-top: 60px;
      width: 942px;
      text-align: center;
      color: var(--white);
    }

    .semanticalApproach {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      color: var(--white);

      .block {
        margin-top: 50px;

        &.left {
          width: 50%;
          p:nth-child(3) {
            font-family: var(--font-family-LT);
          }
        }

        &.right {
          width: 45%;
          display: flex;
          flex-direction: column;

          padding-bottom: 10px;

          .firstSentence {
            font-family: var(--font-family);
            font-weight: var(--font-weight-bold);
            margin-top: 40px;
            margin-left: 25px;
            font-size: 22px;
            color: var(--white);
            width: 60%;
          }

          .dataSentence {
            font-family: var(--font-family-LT);
            font-size: 60px;
            color: var(--yellow);
          }

          .localisationSentence {
            text-align: center;
            font-size: 22px;
            color: var(--white);
            margin-top: -10px;
            margin-right: -150px;
          }
        }
      }
    }

    .bottomText {
      font-size: 16px !important;
      margin-top: 40px;
      color: var(--white);
    }
  }

  .cardsContainer {
    display: flex;
    flex-direction: row;

    height: 240px;

    .card {
      position: relative;

      width: 460px;
      height: 200px;

      margin: 15px;
      margin-top: 40px;

      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

      &:hover {
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.1);
      }

      .baseline {
        position: absolute;

        left: 158px;
        top: 25px;

        font-family: var(--font-family);

        width: 60%;

        font-size: 22px;
        font-weight: 500;
      }

      .product {
        position: absolute;
        width: 200px;

        left: 160px;
        top: 95px;

        & .big {
          font-family: var(--font-family-XB);
          font-size: 30px;
          font-weight: 500;
        }

        & .small {
          top: 135px;
          font-family: var(--font-family-XB);
          font-size: 20px;
          font-weight: 500;
        }

        & .white {
          color: var(--white);
        }

        & .yellow  {
          color: var(--yellow);
        }
      }

      .bigProductTitle {
        line-height: 170%;
      }

      .arrow {
        position: absolute;

        right: 40px;
        top: 130px;

        width: 36px;
        height: 30px;

        &.white {
          color: var(--white);
        }

        &.yellow  {
          color: var(--yellow);
        }

        cursor: pointer;
      }

      &.white {
        background-color: white;

        .icon {
          position: absolute;
          left: 46px;
          top: 48px;

          width: 73px;
          height: 94px;
        }
      }

      &.yellow {
        background-color: var(--yellow);

        .icon {
          position: absolute;
          left: 25px;
          top: 63px;

          width: 114px;
          height: 77px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1180px) {
  .sectionTechnologicalApproach {
    height: auto;
    padding-bottom: 50px;
    .wrapper {
      .title {
        font-size: 40px;
      }

      .description {
        width: 100%;
      }

      .cardsContainer {
        height: auto;
        flex-direction: column;
      }

      .semanticalApproach {
        flex-direction: column;
        align-items: center;

        .block {
          align-items: center;
          &.left {
            width: 100%;
          }

          &.right {
            .firstSentence {
              width: 30%;
            }
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .sectionTechnologicalApproach {
    height: auto;
    padding-bottom: 50px;
    .wrapper {
      .title {
        text-align: center;
        font-size: 36px;
      }

      .description {
        width: 100%;
      }

      .cardsContainer {
        height: auto;
        flex-direction: column;
      }

      .semanticalApproach {
        flex-direction: column;
        align-items: center;

        .block {
          align-items: center;
          &.left {
            width: 100%;
          }

          &.right {
            .firstSentence {
              width: 60%;
            }
            .dataSentence {
              font-size: 40px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .sectionTechnologicalApproach {
    .wrapper {
      .semanticalApproach {
        .block {
          &.right {
            .firstSentence {
              width: 80%;
            }
          }
        }
      }

      .cardsContainer {
        margin-top: 30px;
        height: auto;
        flex-direction: column;

        .card {
          margin-top: 10px;
          margin-bottom: 10px;
          width: 312px !important;
          height: 312px !important;

          .baseline {
            width: 40%;
          }

          .product {
            left: 40px;
            top: 195px;
          }

          .arrow {
            right: 30px;
            top: 210px;
          }
        }
      }
    }
  }
}
