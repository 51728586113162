.sectionIntroductionForTechnology {
  display: flex;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-top: 80px;

  .wrapper {
    display: flex;
    flex-direction: row;
    height: 392px;
    width: 100%;
  }

  .leftCol {
    display: flex;
    flex-direction: column;
    height: 392px;
    text-align: center;

    .grayBloc {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 150px;
      padding-right: 80px;
      padding-left: 250px;
      width: 820px;
      height: 328px;
      background-color: #d2d2d7;
      margin-top: -60px;

      .textBlock {
        text-align: left;
        font-family: var(--font-family-LT);
        font-size: 22px;
      }

      margin-bottom: 20px;
    }

    .underText {
      font-family: var(--font-family);
      font-weight: var(--font-weight-bold);

      font-size: 16px;

      .yellowText {
        margin: 0 5px;
        color: var(--yellow);
      }
    }
  }

  .videoWrapper {
    position: relative;

    height: 326px;
    width: 580px;
    margin-right: -200px;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  // .videoWrapper {
  //   height: 326px;
  //   width: 580px;
  //   background-color: var(--general-blue);
  //   margin-left: -200px;
  // }
}

@media only screen and (max-width: 1180px) {
  .sectionIntroductionForTechnology {
    .wrapper {
      height: auto;
    }

    .leftCol {
      justify-content: space-between;
      height: auto;
      .grayBloc {
        width: 90%;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 150px;
        padding-left: 80px;
        padding-top: 50px;

        height: 800px;

        .textBlock {
          text-align: right;
        }
      }

      .underText {
        margin-top: -60px;
      }
    }

    .videoWrapper {
      min-height: 326px;
      min-width: 580px;
      margin-top: 300px;
      margin-right: -400px;
    }
  }
}

@media only screen and (max-width: 940px) {
  .sectionIntroductionForTechnology {
    .leftCol {
      .grayBloc {
        width: 90%;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 150px;
        padding-top: 100px;
        padding-right: 50px;
        padding-left: 80px;

        height: 900px;

        .textBlock {
          text-align: right;
        }
      }

      .underText {
        margin-top: -60px;
      }
    }

    .videoWrapper {
      margin-top: 350px;
      margin-right: -400px;
    }
  }
}

@media only screen and (max-width: 780px) {
  .sectionIntroductionForTechnology {
    .wrapper {
      width: 100%;
      margin-bottom: 50px;
      flex-direction: column;
      margin-top: 200px;
    }
    .leftCol {
      .grayBloc {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 150px;
        padding-top: 100px;
        padding-right: 50px;
        padding-left: 80px;

        height: 800px;
        margin-top: -520px;

        .textBlock {
          text-align: left;
        }
      }

      .underText {
        margin-top: -60px;
      }
    }

    .videoWrapper {
      min-height: 200px;
      min-width: 359px;
      width: 449px;
      height: 260px;
      align-self: center;
      justify-self: center;
      margin-top: 200px;
      margin-bottom: -150px;
      margin-right: 0px;
    }
  }
}

@media only screen and (max-width: 580px) {
  .sectionIntroductionForTechnology {
    .wrapper {
      margin-top: 450px;
    }
    .leftCol {
      .grayBloc {
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 150px;
        padding-top: 50px;
        padding-right: 50px;
        padding-left: 80px;

        height: 700px;
        .textBlock {
          font-size: 18px;
        }
      }

      .underText {
        font-size: 14px;
      }
    }

    .videoWrapper {
      margin-top: -300px;

      position: relative;
      padding-bottom: 56.25%;
      padding-top: 0px;
      height: 0;
      min-height: 0px;
      width: 0;
      min-width: 90%;
      margin-bottom: -50px;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .sectionIntroductionForTechnology {
    margin-top: 180px;
    .leftCol {
      .grayBloc {
        height: 652px;
        padding: 30px;
      }
    }

    .videoWrapper {
      margin-top: -250px;
    }
  }
}

@media only screen and (max-width: 320px) {
  .sectionIntroductionForTechnology {
    .leftCol {
      .grayBloc {
        height: 492px;
        padding: 30px;
      }
    }

    .videoWrapper {
      margin-top: -220px;
    }
  }
}
