.uploaderPage {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
}

.uploaderSmallBackground,
.uploaderBigBackground {
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}

.uploaderSmallBackground {
  background-image: url('../../assets/images/uploader_bg_sm.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.uploaderBigBackground {
  background-image: url('../../assets/images/uploader_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}

.orangeLink {
  color: var(--yellow);

  &:hover {
    text-decoration: none;
  }
}

.authorLinks {
  font-size: 12px;
  line-height: 18px;
  color: var(--black-real);
}

.isAppliedContainer {
  min-height: 400px;
  font-weight: 500;
  text-align: center;
}

.isAppliedTitle {
  margin: 45px auto 60px auto;
  font-size: 40px;
  line-height: 47px;
  text-transform: uppercase;
}

.isAppliedMessage {
  font-size: 20px;
  line-height: 21px;
}
