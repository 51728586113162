.wrapper {
  height: 429px;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-bottom: 150px;
}

.clientsTestify {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  background-color: var(--yellow);
  padding-right: 200px;
  padding-top: 70px;
}

.clientsTestifyTitleWrapper {
  max-width: 400px;
  text-align: right;
}

.clientsTestifyTitle {
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-size: 60px;
  line-height: 60px;
  color: var(--general-blue);
}

.carouselWrapper {
  position: relative;
  background: var(--white);

  width: 820px;
  margin-left: -140px;
  margin-top: 70px;
}

.post {
  display: flex;
  background: var(--white);
  width: 820px;
  min-height: 470px;
  padding: 20px;
}

.postLeftPartAva {
  img {
    height: 266px;
    width: 266px;
  }
}

.postLeftPartLogo {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 15px;

  img {
    height: 52px;
    width: 120px;
  }
}

.postRightPart {
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 65px;
}

.postsText {
  display: flex;
  flex-direction: column-reverse;
  min-height: 266px;
  margin-bottom: 20px;

  p {
    font-family: var(--font-family-SBi);
    font-size: 26px;
    line-height: 31px;
  }
}

.postAuthorNameContainer {
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  font-size: 16px;
  line-height: 21px;
  color: var(--yellow);
}

@media only screen and (max-width: 1180px) {
  .wrapper {
    height: auto;
    flex-direction: column;
  }

  .clientsTestify {
    height: 500px;
    width: 70%;
  }

  .carouselWrapper {
    width: 90%;

    margin-left: 5%;
    margin-top: -280px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.1);
  }

  .post {
    width: 80%;
  }
}

@media only screen and (max-width: 980px) {
  .post {
    flex-direction: column;
    align-items: center;
  }

  .postLeftPartLogo {
    justify-content: center;
  }
}

@media only screen and (max-width: 840px) {
  .clientsTestify {
    padding-right: 100px;
  }
}

@media only screen and (max-width: 680px) {
  .wrapper {
    height: auto;
    flex-direction: column;
  }

  .carouselWrapper {
    margin-top: -300px;
    padding-bottom: 60px;
  }

  .clientsTestify {
    height: 500px;
    width: 90%;
    padding-right: 50px;
  }

  .clientsTestifyTitle {
    font-size: 36px;
    line-height: 41px;
  }

  .post {
    width: 100%;
  }

  .postRightPart {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }

  .postsText {
    display: flex;
    flex-direction: column-reverse;
    min-height: 266px;
    margin-bottom: 20px;

    p {
      font-family: var(--font-family-SBi);
      font-size: 21px;
      line-height: 26px;
    }
  }
}
