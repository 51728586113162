.DisclaimerPage {
  padding: 3rem 0;

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    line-height: 1.5;
  }

  .title {
    text-transform: uppercase;
    color: var(--general-blue);
    font-family: var(--font-family);
    font-size: 32px;
    margin: 0 0 8px;
    font-weight: 500;
    text-align: center;
  }

  .text {
    padding: 0 15px;
  }

  p {
    font-family: var(--font-family);
    font-size: var(--font-size-large);
    line-height: 1.5;
    margin-bottom: 16px;
  }
}
