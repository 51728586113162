.articleContainer {
  display: flex;
  // min-height: 200vh; //to remove
  background-color: #ffd300;
  align-items: center;
  flex-direction: column;
  padding: 100px;

  .headerBanner {
    height: 150px;
    width: auto;

    margin-bottom: 40px;
  }

  .articleCard {
    display: flex;
    flex-direction: column;

    // border-radius: 10px;
    background-color: var(--white);
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.1);
    width: 90%;
    min-height: 180vh;
    padding: 50px;
    margin-top: 50px;
    align-items: center;

    h1 {
      text-align: left;
      color: var(--general-blue);
      font-size: 60px;
      max-width: 25ch;
    }

    .articleBodyContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      .articleBody {
        text-align: justify;
        width: 80%;
        padding: 50px;

        p {
          margin-top: 15px;
          line-height: var(--font-size-xlarge);
          font-size: var(--font-size-large);
        }

        ul {
          padding-top: 20px;
          margin-left: 60px;
          margin-bottom: 40px;
        }

        p.separator {
          margin: 30px 0px;
          text-align: center;
        }

        p.author {
          margin-top: 80px;
          text-align: right;
        }

        p.date {
          text-align: right;
        }
      }
    }

    .footerBanner {
      width: 100%;
      max-height: 300px;
    }
  }
}

@media only screen and (max-width: 1350px) {
  .articleContainer {
    padding: 50px;
    .articleCard {
      width: 90%;

      h1 {
        font-size: 40px;
      }
      .articleBodyContainer {
        .articleBody {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 880px) {
  .articleContainer {
    padding: 50px 0px;
    .articleCard {
      width: 100%;

      h1 {
        font-size: 40px;
      }
      .articleBodyContainer {
        .articleBody {
          padding: 0;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .articleContainer {
    padding: 50px 0px;
    .articleCard {
      width: 100%;

      h1 {
        font-size: 36px;
      }
      .articleBodyContainer {
        .articleBody {
          padding: 0;
          width: 100%;
        }
      }
    }
  }
}
