.count {
  font-size: 18px;
  line-height: 27px;
  color: var(--black);
}

.fileItem {
  display: flex;
  align-items: center;
  padding: 16px 20px;

  &:after {
    display: block;
    width: 100px;
    height: 1px;
    border-bottom: 5px solid red;
  }
}

.icon {
  width: 40px;
  height: 40px;
  margin: 0 10px 0 5px;
}

.fileNameWrapper {
  display: flex;
  align-items: center;
  align-self: flex-end;
  position: relative;
}

.fileName {
  font-size: 20px;
  line-height: 30px;
  color: var(--black-real);
}

.closeFile {
  display: flex;
  cursor: pointer;
  margin-left: 10px;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
}

.disabled {
  opacity: 0.4;

  &:hover {
    opacity: 0.4;
    cursor: auto;
  }
}

.fileErrorMessage {
  position: absolute;
  min-width: max-content;
  bottom: -16px;
  left: 0;
  color: var(--error);
  font-size: 14px;
  line-height: 21px;
  margin-top: -5px;
}

.fileUploading {
  position: absolute;
  min-width: max-content;
  bottom: -16px;
  left: 0;
  color: var(--black-real);
  font-size: 14px;
  line-height: 21px;
  margin-top: -5px;

  &:after {
    content: '.';
    position: absolute;
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: var(--black-real);
      text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow: 0.25em 0 0 var(--black-real), 0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow: 0.25em 0 0 var(--black-real), 0.5em 0 0 var(--black-real);
    }
  }
}

.resultCirclesContainer {
  display: flex;
  align-items: center;
  margin-left: 15px;
  position: relative;
  min-width: 250px;
}

.erroredParsingItemDetails {
  display: flex;
  align-items: center;
}

.parsingRespError {
  position: absolute;
  top: -18px;
  padding: 2px 6px;
  left: 0;
  background: rgba(106, 106, 106, 0.8);
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  color: var(--white);
}

.retryBtnContainer {
  padding-left: 30px;
}

.retryBtn {
  margin: 0;
  border: 2px solid var(--yellow);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0 13px;
  min-width: 60px;
  max-width: fit-content;
  min-height: 21px;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: var(--yellow);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;

  &:hover {
    color: var(--white);
    background-color: var(--yellow);
    border-color: var(--yellow);
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:active {
    background-color: var(--yellow) !important;
    border-color: var(--yellow) !important;
    color: var(--white) !important;

    &:focus {
      box-shadow: none !important;
      outline: none;
    }
  }

  &:disabled {
    &,
    &:hover {
      color: var(--yellow);
      background-color: transparent;
      border-color: var(--yellow);
      cursor: auto;
    }
  }
}
