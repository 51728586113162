.main {
  display: flex;
  background: url(/assets/images/BackgroundHomeHeader.png) no-repeat right;
  background-size: cover;
  width: 62%;
  margin-right: 30%;
  overflow: hidden;
}

.content {
  padding: 10px 14%;
  transition: opacity 0s linear 0.6s;
}

.title {
  font-size: 60px;
  line-height: 65px;
  color: var(--yellow);
}

.text {
  font-size: 22px;
  line-height: 27px;
  color: var(--white);
}

.scrollButton {
  position: absolute;
  bottom: 10px;

  img {
    width: 24px;
    height: 38px;
  }
}

.hidden {
  .content {
    opacity: 0;
  }
}

@media only screen and (max-width: 1350px) {
  .main {
    width: 100%;
  }

  .hidden {
    height: 0px;
  }

  .title {
    font-size: 40px;
    line-height: 45px;
    margin-top: 100px;
  }

  .text {
    margin-bottom: 100px;
  }
}
