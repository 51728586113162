.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--general-blue);
}

.yellow {
  background: var(--yellow);
}
.blue {
  background: var(--general-blue);
}

.news {
  display: flex;
  flex-direction: column;
  width: 1180px;
  padding: 40px 0 270px 0;
}

.title {
  margin: 0 auto;
  padding-bottom: 65px;
  font-family: var(--font-family);
  font-size: 60px;
  line-height: 70px;
  font-weight: var(--font-weight-bold);
  color: var(--yellow);
}

.seeEverythingButton {
  margin: 40px 0 0 auto;
}
