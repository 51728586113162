.section {
  .wrapper {
    max-width: 2000px;
    min-height: 550px;
    padding: 70px 15px;
    margin: 0 auto;
    background-image: url('../../../assets/images/impact_rh_bi/background_how_it_works.png');
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .container {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    font-size: 60px;
    line-height: 65px;
    margin-bottom: 30px;
    color: #e0bc37;
  }

  .contentWrapper {
    display: flex;
  }

  .textContainer {
    max-width: 60%;
    padding-right: 70px;
    font-size: 16px;
    line-height: 26px;
    color: var(--black);

    p {
      margin-bottom: 1rem;
      font-size: var(--font-size-large);
      font-family: var(--font-family);

      &:first-child {
        font-family: var(--font-family);
        font-weight: var(--font-weight-bold);
      }
    }

    li {
      font-family: var(--font-family);
      font-size: var(--font-size-small);
      margin-left: 20px;

      &:first-child {
        margin-bottom: 15px;
      }
    }

    span {
      font-weight: var(--font-weight-bold);
      text-decoration: underline;
      font-style: italic;
    }
  }

  .howItWorksImgContainer {
    max-width: 30%;
    padding-top: 55px;

    img {
      width: 100%;
      height: auto;
    }
  }

  .ourCustomersTestify {
    display: flex;
    flex-direction: column;
    background-color: var(--general-blue);
    padding: 50px 20px 35px 20px;
    margin-bottom: 80px;
  }

  .ourCustomersTestifyTitle {
    margin-bottom: 50px;
    text-transform: uppercase;
    font-family: var(--font-family-LT);
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    color: var(--yellow);
  }
}

@media only screen and (max-width: 880px) {
  .section {
    .wrapper {
      background-image: unset;
      .title {
        text-align: center;
      }
      .contentWrapper {
        flex-direction: column;
      }

      .textContainer {
        padding-right: 0;
        max-width: 100%;
      }

      .howItWorksImgContainer {
        max-width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .section {
    .wrapper {
      .title {
        font-size: 36px;
        line-height: 45px;
      }
    }
  }
}
