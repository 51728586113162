.sectionATSConnexion {
  height: auto;

  .wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;

    .leftCol {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-top: 55px;
      width: 55%;
      padding-bottom: 50px;

      padding-right: 15%;

      background-color: var(--general-blue);

      .title {
        text-align: right;
        font-family: var(--font-family);
        font-size: 60px;
        line-height: 60px;
        font-weight: var(--font-weight-bold);
        color: var(--yellow);
        margin-top: 0;
        margin-bottom: 0px;

        width: 80%;
      }

      .description {
        text-align: right;
        font-size: 16px;
        line-height: 21px;
        line-height-step: 10px;
        font-family: var(--font-family-LT);
        font-weight: var(--font-weight-normal);
        color: var(--white);
        width: 55%;
        margin-top: 50px;
      }
    }

    .rightCol {
      display: flex;
      flex-direction: row;

      width: 45%;

      align-items: center;
      .card {
        display: flex;
        flex-direction: column;
        width: 620px;
        height: 510px;

        margin-left: -100px;

        background-color: var(--white);
        box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.1);

        .titleContainer {
          padding: 40px;
          padding-left: 65px;

          .cardTitle {
            font-family: var(--font-family-LT);
            font-size: 40px;
            font-weight: 300;
            color: var(--general-blue);
            text-align: left;
          }
        }

        .logos {
          display: flex;
          flex-direction: row;

          justify-content: center;
          flex-wrap: wrap;

          .logoContainer {
            align-self: flex-start;
            justify-self: flex-start;
            width: 242px;
            height: 72px;

            margin-right: 20px;
            margin-left: 20px;
            margin-top: 10px;

            img {
              max-width: 100%;
              width: auto;
              height: auto;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1180px) {
  .sectionATSConnexion {
    .wrapper {
      position: relative;
      flex-direction: column;

      .leftCol {
        width: 80%;
        padding-bottom: 600px;

        .title {
          font-size: 50px;
        }
      }

      .rightCol {
        justify-content: center;
        width: 100%;
        .card {
          position: absolute;
          right: 20px;
          margin-top: -580px;
          margin-left: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .sectionATSConnexion {
    .wrapper {
      .leftCol {
        padding-right: 30px;

        .description {
          width: 80%;
        }
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .sectionATSConnexion {
    .wrapper {
      .rightCol {
        justify-content: center;
        width: 100%;
        margin: 0px;
        .card {
          width: 90%;
          height: auto;

          .titleContainer  {
            .title {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .sectionATSConnexion {
    .wrapper {
      .leftCol {
        width: 90%;
        padding-bottom: 900px;

        .title {
          width: 90%;
          font-size: 36px;
          line-height: 41px;
        }
      }
      .rightCol {
        justify-content: center;
        width: 100%;
        .card {
          margin-top: 50px;
          width: 90%;
          margin-top: -880px;
          height: auto;
          padding-bottom: 50px;

          .titleContainer  {
            .title {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .sectionATSConnexion {
    .wrapper {
      .leftCol {
        padding-bottom: 900px;
      }
      .rightCol {
        margin-bottom: 100px;
        .card {
          margin-top: -780px;
        }
      }
    }
  }
}
