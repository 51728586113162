.card {
  display: flex;
  flex-direction: column;

  width: 100%;

  height: auto;
  background-color: var(--white);
  margin-bottom: 60px;

  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 20px;

    img {
      align-self: center;
      width: 50%;
      height: auto;
    }

    .postText {
      display: flex;
      flex-direction: column-reverse;
      min-height: 266px;
      margin-bottom: 20px;

      p {
        font-family: var(--font-family-SBi);
        font-size: 21px;
        line-height: 25px;
      }
    }

    .postAuthorNameContainer {
      font-weight: var(--font-weight-bold);
      font-size: 16px;
      line-height: 21px;
      color: var(--yellow);
    }
  }

  .videoContainer {
    margin-top: 20px;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;

    margin-bottom: 40px;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .accordionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 55px;
    border-top: 1px solid var(--general-blue);
    padding: 10px 20px;
    span {
      font-family: var(--font-family-XB);

      font-size: 22px;
      color: var(--yellow);
    }
    cursor: pointer;
  }

  .accordionContent {
    padding: 0px 20px;
    height: 0;

    opacity: 0;
    visibility: hidden;
    transition: all 0.4s, visibility 0s;

    &.displayed {
      visibility: visible;

      padding: 10px 20px;
      opacity: 1;
      height: auto;
    }

    ol {
      font-family: var(--font-family-SB);
      list-style: none;
      margin-bottom: 25px;

      li:before {
        content: '> ';
      }
    }

    ul {
      list-style: none;
      margin-bottom: 25px;

      li:before {
        content: '• ';
      }

      li {
        ul {
          list-style: none;
          margin-bottom: 25px;

          li:before {
            content: '- ';
            padding-left: 18px;
          }
        }
      }
    }

    &.blockResult {
      ul {
        li {
          font-family: var(--font-family);
          font-weight: var(--font-weight-bold);
        }

        li {
          &:nth-child(3),
          &:nth-child(4) {
            font-family: var(--font-family);
            font-weight: var(--font-weight-normal);
          }
        }

        li {
          &.bold {
            font-family: var(--font-family);
            font-weight: var(--font-weight-bold);
          }
        }

        li > span {
          font-family: var(--font-family);
          font-weight: var(--font-weight-normal);
        }

        li > ul {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .card {
    .header {
      img {
        width: 80%;
      }
    }
  }
}

@media only screen and (max-width: 680px) {
  .card {
    .header {
      img {
        width: 100%;
      }
    }
  }
}
