.select {
  font-family: var(--font-family-SB);
  border: none;
  height: 40px;
  border-bottom: 5px solid var(--general-blue);
  padding: 7px 16px;

  &:focus {
    outline: none;
  }

  .option {
    font-size: var(--font-size-small);
    line-height: var(--line-height-midle);
  }
}
