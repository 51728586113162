.sectionPartners {
  background: #fafafa;
  width: 100%;
  height: 253px;
  padding-top: 40px;
  padding-bottom: 40px;

  // button {
  //   display: none;
  // }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .title {
    font-family: var(--font-family-LT);
    font-size: 40px;
    line-height: 47px;
    font-weight: var(--font-weight-bold);
    color: var(--general-blue);
    margin-bottom: 23px;
    text-align: center;
    text-transform: uppercase;
  }

  .logos {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-content: center;
    list-style: none;

    .logo {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      height: auto;
    }
  }
}

@media only screen and (max-width: 840px) {
  .sectionPartners {
    .title {
      font-size: 30px;
    }
  }
}

@media only screen and (max-width: 680px) {
  .sectionPartners {
    .title {
      font-size: 20px;
    }
  }
}
