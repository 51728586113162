.footer {
  display: flex;
  justify-content: center;
  font-family: var(--font-family);
  font-weight: var(--font-weight-medium);
  background-color: var(--dark-yellow);
  height: 420px;
}

a {
  color: var(--general-blue);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.wrapper {
  display: flex;
  padding: 55px 0 15px 0;
  width: 940px;
}

.leftPanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 350px;

  span {
    display: block;
  }

  .topSide {
    span {
      font-family: var(--font-family-XB);
      font-size: var(--font-size-midle);
      line-height: 19px;
    }
  }

  .innovations {
    display: block;
    width: 200px;
  }

  .footerBottom {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);

    span {
      border-left: 1px solid var(--white);
      padding: 0 5px;

      &:first-child {
        border: none;
        padding-left: 0;
      }
    }
  }
}

.rightPanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2%;

  .footerBottom {
    font-family: var(--font-family-LT);
  }

  .rightTopBlock {
    display: flex;
  }
}

.blocks {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  span {
    font-size: var(--font-size-small);
    line-height: var(--line-height-large);
  }
}

.block {
  display: flex;
  flex-direction: column;
  width: 33.33%;
  list-style: none;
  font-style: normal;
  font-family: var(--font-family-BK);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-small);
  line-height: var(line-height-large);
  margin-right: 40px;
  white-space: nowrap;

  a {
    cursor: pointer;

    &:first-of-type {
      font-family: var(--font-family);
      font-weight: var(--font-weight-bold);
      color: var(--white);
    }
  }
  span {
    display: block;
    margin-bottom: 10px;
  }
}

.adressBlock {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 440px;
  font-style: normal;
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-small);
  line-height: var(line-height-large);
  margin-right: 40px;
  white-space: nowrap;

  span {
    display: block;
    margin-bottom: 10px;
  }

  .adressItemContainer {
    display: flex;
  }

  .adressItem {
    color: var(--white);
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
    margin-right: 10px;
  }
}

.stationF {
  width: 100%;
  height: auto;
}

.footerBottom {
  display: flex;
  flex-direction: row;

  a {
    border-left: 1px solid var(--general-blue);
    padding-right: 5px;
    padding-left: 5px;
    cursor: pointer;

    &:first-child {
      border: none;
      padding-left: 0;
    }
  }

  span {
    font-family: var(--font-family);
    font-style: normal;
    font-size: var(--font-size-xsmall);
    line-height: 19px;
  }
}

.networkPanel {
  display: flex;
  list-style: none;
  margin-top: 30px;
  padding: 0;

  li {
    margin-right: 16px;
    transition: transform 0.15s ease-in;
    transform: translateY(0px);

    &:hover {
      transition: transform 0.15s ease-in;
      transform: translateY(-5px);
    }
  }
}

.logo {
  &:after {
    display: block;
    content: ' ';
    width: 30px;
    height: 3px;
    background-color: #fff;
    border-radius: 1.5px;
    margin-top: 17px;
    margin-bottom: 8px;
  }
  // img { // static logo style
  //   width: 150px;
  //   height: 22px;
  // }

  img {
    width: 250px;
    height: auto;
    margin-top: -50px;
    margin-bottom: -60px;
    margin-left: -35px;
  }
}

.netIcons {
  img {
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 1180px) {
  .footer {
    height: auto;
  }

  .wrapper {
    width: 100%;
  }

  .rightTopBlock {
    flex-direction: column;

    .adressBlock {
      margin-top: 30px;
    }
  }

  .netIcons {
    img {
      width: 31px;
      height: 31px;
    }
  }
}

@media screen and (max-width: 840px) {
  .wrapper {
    flex-direction: column;
  }

  .rightPanel {
    margin-left: 0px;
    margin-top: 30px;
  }

  .footerBottom {
    font-size: 12px;
    justify-content: center;
  }

  .footerBottomAlt {
    font-size: 12px;
    margin: 5px 0px;
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    display: flex;
    justify-content: center;

    span {
      border-left: 1px solid var(--white);
      padding: 0 5px;

      &:first-child {
        border: none;
        padding-left: 0;
      }
    }
  }

  .adressBlock {
    width: 100% !important;

    .adressItemContainer {
      flex-direction: column;
    }

    .adress {
      margin-top: -8px;
    }
  }
}

@media screen and (max-width: 481px) {
  .footerBottom {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;

    a {
      &:last-child {
        border: none;
      }
    }
  }

  .stationF {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 380px) {
  .adressItemContainer .adress {
    font-size: 12px;
  }

  .footerBottom {
    a {
      &:last-child {
        border-left: 1px solid var(--general-blue);
      }
    }
  }
}
