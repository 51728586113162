.post {
  display: flex;
  justify-content: space-between;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
  background: var(--white);
  width: 100%;
}

.postLeftPart {
  width: 37%;
}

.postLeftPartLogo {
  display: flex;
  padding-bottom: 80px;

  img {
    height: 86px;
    width: 198px;
  }
}

.postText {
  display: flex;
  flex-direction: column-reverse;
  min-height: 266px;
  margin-bottom: 20px;

  p {
    font-family: var(--font-family-SBi);
    font-size: 26px;
    line-height: 31px;
  }
}

.postAuthorNameContainer {
  font-family: var(--font-family-XB);
  font-size: 16px;
  line-height: 21px;
  color: var(--yellow);
}

.postRightPart {
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 0 60px 0 30px;
  font-size: 16px;
  line-height: 21px;

  .title {
    font-family: var(--font-family-XB);
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 0.5rem;
    color: var(--yellow);
  }

  ol {
    font-family: var(--font-family-SB);
    list-style: none;
    margin-bottom: 25px;

    li:before {
      content: '> ';
    }

    li {
      &:first-child {
        font-family: var(--font-family-XB);
      }
    }
  }

  ul {
    list-style: none;
    margin-bottom: 25px;

    li:before {
      content: '• ';
    }

    li {
      ul {
        list-style: none;
        margin-bottom: 25px;

        li:before {
          content: '- ';
          padding-left: 18px;
        }
      }
    }
  }

  .row {
    display: flex;

    & > div:first-child {
      margin-right: 50px;
    }
  }
}

.videoContainer {
  margin-top: 20px;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.blockResult {
  ul > li {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);

    span {
      font-family: var(--font-family);
      font-weight: var(--font-weight-normal);
    }
  }

  ul > li > ul {
    li {
      &:last-child {
        font-family: var(--font-family);
        font-weight: var(--font-weight-normal);
      }

      &.bold {
        font-weight: var(--font-weight-bold);
      }
    }
  }
}
