.sectionTechnologicalPartners {
  min-height: 594px;
  background-color: #e0bc37;
  padding-top: 41px;
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      text-transform: uppercase;
      font-family: var(--font-family-LT);
      font-size: 40px;
      line-height: 47px;
      font-weight: var(--font-weight-medium);
      color: var(--white);
      margin-top: 0;
      margin-bottom: 44px;
      text-align: center;
    }

    .description {
      width: 942px;

      margin-top: 0;
      margin-bottom: 44px;

      text-align: center;
      font-size: 16px;
      line-height: 21px;
      font-weight: var(--font-weight-normal);
      color: var(--general-blue);
    }

    .logos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      justify-content: center;
      flex-wrap: wrap;

      .logoContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 211px;
        height: 88px;

        margin: 30px;

        img {
          width: auto;
          height: auto;
        }

        .logo {
          max-width: 211px;
          max-height: 88px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1180px) {
  .sectionTechnologicalPartners {
    .wrapper {
      .description {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .sectionTechnologicalPartners {
    .wrapper {
      .title {
        font-size: 36px;
      }
    }
  }
}
