.headerDropdown {
  background-color: var(--white);
  position: absolute;
  top: 50px;
  right: 0;
  z-index: 3;
  width: 25%;
  height: auto;
  padding: 0;
}

.headerDropdownList {
  list-style: none;
}

.headerDropdownElem {
  border-top: 1px solid var(--general-blue);
}

.headerDropdownLink {
  display: block;
  position: relative;
  font-size: 13px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 11px 16px;

  &:after {
    display: block;
    content: ' ';
    position: absolute;
    bottom: -3px;
    margin-left: 50%;
    width: 0;
    height: 3px;
    background-color: var(--yellow);
    transition: all 0.3s linear 0s;
    transform: translateY(-50%);
  }

  &:hover {
    color: var(--yellow);
    text-decoration: none;

    &:after {
      width: 100%;
      margin-left: 0;
      left: 0;
    }
  }
}

@media screen and (max-width: 840px) {
  .headerDropdown {
    width: 50%;
  }
}
