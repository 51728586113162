.sectionComment {
  background-image: url('../../../assets/images/x_cv_ranking/bg_comment.png');
  background-repeat: no-repeat;
  background-position: top right;
  font-family: var(--font-family);
  font-weight: var(--font-weight-normal);
  width: 100%;
  padding-top: 58px;

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .title {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
    font-size: 60px;
    line-height: 65px;
    color: var(--yellow);
    margin-bottom: 33px;
  }

  .paragraph {
    max-width: 700px;
    font-family: var(--font-family);
    font-size: var(--font-size-midle);
    line-height: 26px;
    margin-bottom: 20px;
  }

  .firstParagraph {
    font-family: var(--font-family);
    font-weight: var(--font-weight-bold);
  }

  .blockImage {
    background-image: url('../../../assets/images/x_cv_ranking/XParsingRnD_blurred.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    max-width: 100%;
    min-width: 90%;
    height: 610px;
    margin: 69px 44px 0;
  }

  .buttonContainer {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 1230px) {
  .sectionComment {
    background-image: unset;

    .wrapper {
      align-items: center;
    }
  }
}

@media only screen and (max-width: 800px) {
  .sectionComment {
    .blockImage {
      height: 310px;
    }
  }
}

@media only screen and (max-width: 530px) {
  .sectionComment {
    .title {
      font-size: 36px;
      line-height: 42px;
    }
    .blockImage {
      height: 210px;
    }
  }
}
